import React, {forwardRef, useImperativeHandle, useRef} from "react";
import cx                                               from "classnames";
import {useAudio}                                       from "hooks/global/useAudio";
import {ReactComponent as PauseIcon}                    from "assets/img/svg/pause_btn.svg";
import {ReactComponent as PlayIcon}                     from "assets/img/svg/play_btn.svg";
import {AnonymousFunction}                              from "constants/types/anonymousFunction";
import "./AudioButton.scss";

type AudioButtonProps =
  React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
  & any
  & {
  className?: string,
  customIcons?: {
    play?: any,
    pause?: any,
  },
  fullControl?: boolean,
  invertedColor?: boolean,
  isStatic?: boolean,
  onClick?: AnonymousFunction,
  url?: string,
};

/**
 * AudioButton Functional Component
 * TODO: use lottie web for better transition
 * @param {string} className - used to set a class on a higher element tag
 * @param invertedColor
 * @param isStatic
 * @param onClick
 * @param url
 * @return {React.FC<AudioButtonProps>}
 */
const AudioButton: React.FC<AudioButtonProps> = forwardRef(({
                                                              className,
                                                              customIcons,
  fullControl,
                                                              invertedColor,
                                                              isStatic,
                                                              onClick,
                                                              volume,
                                                              url,
                                                              ...props
                                                            }, ref: any) => {
  const buttonRef         = useRef<HTMLButtonElement | null>(null);
  // @ts-ignore
  const [playing, toggle] = useAudio(url, volume);

  useImperativeHandle(ref, () => ({
    play: () => {
      if (!playing) toggle();
    },
    buttonElement: {...ref.current},
    stop         : () => {
      if (playing) toggle();
    }
  }));

  const classes: string = cx(
    "audio-button",
    {"audio-button--inverted-color": invertedColor},
    className,
  );

  function handleClick(event: any) {
    if (onClick) onClick(event.target); // @ts-ignore
    if (fullControl) return;
    if (!isStatic) toggle();
  }

  const CalculatedPauseIcon = customIcons?.pause || PauseIcon;
  const CalculatedPlayIcon = customIcons?.play || PlayIcon;

  return (
    <button {...props} ref={buttonRef} className={classes} onClick={handleClick} data-is-playing={playing}>
      {playing ? <CalculatedPauseIcon/> : <CalculatedPlayIcon/>}
    </button>
  );
});

export default AudioButton;
