import React, { useMemo, useRef, useState } from "react";
import cx from "classnames";
import Progress from "components/commons/Progress/Progress";
import AudioButton from "components/commons/AudioButton/AudioButton";
import Typography from "components/commons/Typography/Typography";
import Button from "components/commons/Button/Button";
import { Typography as TypographyType } from "constants/enums/typography";
import { useHistory } from "react-router";
import { useParams, useLocation } from "react-router-dom";
import { find, isNil } from "lodash";
import { ReactComponent as PlaySoundIcon } from "assets/img/svg/sound.svg";
import { EarTour, ProductType } from "constants/enums/routePath";
import "./ProductTestSoundCalibration.scss";


// TODO: invert volume
const buttons = [
  {id: 1, subtext: "Audition normale", volume: 0.25},
  {id: 2, subtext: "Perte légère", volume: 0.45},
  {id: 3, subtext: "Perte moyenne", volume: 0.7},
  {id: 4, subtext: "Perte sévère", volume: 1},
];

type ProductTestSoundCalibrationProps = {
  className?: string,
}

/**
 * ProductTestSoundCalibration Functional Component
 * @param {string} className - used to set a class on a higher element tag
 * @return {React.FC<ProductTestSoundCalibrationProps>}
 */
const ProductTestSoundCalibration: React.FC<ProductTestSoundCalibrationProps> = ({className}) => {
  const location                = useLocation();
  const audioBtnRef             = useRef<any>(null);
  const [selected, setSelected] = useState<number | null>(null);
  const history                 = useHistory();                   // @ts-ignore
  const {product_type}          = useParams();
  const volume                  = useMemo(() => {
    if (isNil(selected)) return buttons[0].volume;
    return buttons[selected - 1].volume;
  }, [selected]);

  const classes: string = cx(
    "product-test-sound-calibration",
    className,
  );

  function handleRedirect() {
    // @ts-expect-error
    const volume = find(buttons, {"id": selected})?.volume;

    switch (product_type) {
      case ProductType.Protection:
          history.push('/product-test/protection/discovery', { from: location?.pathname, volume})
        break;
      case ProductType.Device:          
          history.push(`/product-test/discovery/${location.state.product}`, {from: location.pathname, volume});
        break;
      case EarTour.Video:
          history.push(`/experience-tour/${EarTour.Video}`, {from: location.pathname, volume});
        break;
      default:
          history.push(`/product-test/${product_type}/choices`, {from: location?.pathname, volume});
        break;
    }    
  }

  function handleButtonClick(id: number) {
    audioBtnRef.current.play();
    setSelected(id);
  }

  // function handleAudioClick() {
  //   if (!selected) setSelected(1);
  // }

  return (
    <div className={classes}>
      <Typography as={TypographyType.Title} className="product-test-sound-calibration__title">Calibration du
        son</Typography>
      <Typography className="product-test-sound-calibration__subtitle">Choisissez le volume le plus
        confortable</Typography>
      <div className="product-test-sound-calibration__audio-container">
        <AudioButton
          ref={audioBtnRef}
          url={"/assets/audio/product-test/calibration.wav"}
          invertedColor
          className="product-test-sound-calibration__audio-button"
          volume={volume}
          fullControl
          // onClick={handleAudioClick}
          customIcons={{play: PlaySoundIcon, pause: PlaySoundIcon}}
        />
        {/*// @ts-ignore*/}
        <Progress value={25 * selected}/>
      </div>
      <div className="product-test-sound-calibration__volume-btn-container">
        {
          buttons.map(({id, subtext}) => (
            <Button
              className="product-test-sound-calibration__volume-btn"
              data-selected={selected === id}
              data-subtext={subtext}
              key={id}
              onClick={() => handleButtonClick(id)}
            >
              Volume {id}
            </Button>
          ))
        }
      </div>
      <Button className="product-test-sound-calibration__submit-btn" onClick={handleRedirect} disabled={!selected}>
        { product_type === EarTour.Video ? "Reprendre la visite" : "Passer au test"}
      </Button>
    </div>
  );
};

export default ProductTestSoundCalibration;
