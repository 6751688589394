import React, { useState } from "react"
interface IAuthState {
  authed: boolean
  setAuthed: (val: boolean) => void
}

const defaultState = {
  authed: false,
  setAuthed: () => { console.error('Auth provider not found !') }
}

export const AuthContext = React.createContext<IAuthState>(defaultState)

const AuthProvider: React.FC = ({ children }) => {
  const [authed, setAuthed] = useState<boolean>(false)

  return (
    <AuthContext.Provider value={{ authed, setAuthed }}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
