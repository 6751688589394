import React, {useContext}            from "react";
import cx                             from "classnames";
import Typography                     from "components/commons/Typography/Typography";
import {Typography as TypographyType} from "constants/enums/typography";
import {AuthContext}                  from "contexts/global/AuthContext";
import ExpTourQuestionsAccordion      from "features/experience-tour/containers/Accordions/ExpTourQuestionsAccordion";
import TokenService                   from "services/token";
import "./ExperienceTourQuestions.scss";

type ExperienceTourQuestionsProps = {
  className?: string,
}

/**
 * ExperienceTourQuestions Functional Component
 * @param {string} className - used to set a class on a higher element tag
 * @return {React.FC<ExperienceTourQuestionsProps>}
 */
const ExperienceTourQuestions: React.FC<ExperienceTourQuestionsProps> = ({className}) => {
  const { setAuthed } = useContext(AuthContext)

  const classes: string = cx(
    className,
    "experience-tour-questions",
  );

  /**
   * Handle logout after a delay of 5 seconds
   */
  function handleLogout(): void {
      TokenService.removeUser()
      setAuthed(false)
  }

  return (
    <div className={classes}>
      <Typography as={TypographyType.Title} className="experience-tour-questions__title">
        Mieux comprendre
        <button className="experience-tour-questions__hidden-logout-btn" onDoubleClick={handleLogout}>Déconnexion</button>
      </Typography>
      <ExpTourQuestionsAccordion/>
    </div>
  );
};

export default ExperienceTourQuestions;
