export function useExpTourQuestionsRessources(): { id: string, title: string, value: string }[] {

  // TODO intl
  return [
    {
      id: "natural_clear_sound",
      title: "Comment fonctionne l'oreille",
      value: "<p>De la création d’un son à sa perception, le travail effectué par le système auditif est prodigieux.</p>" +
        "<p>L’oreille se décompose en trois parties: l’oreille externe, l’oreille moyenne et l’oreille interne.&nbsp;</p>" +
        "<p>Chaque partie a un rôle bien déterminé: de la collecte du message sonore jusqu’à son acheminement vers le cortex cérébral.</p>" +
        "<p>En détail, l'oreille externe puis l'oreille moyenne transmettent et amplifient mécaniquement le son à l'oreille interne, responsable de la transformation de la vibration en impulsions électriques.</p>"
    },
    {
      id: "clear_speaking_comprehension",
      title: "Le son, une expérience individuelle",
      value: "<p>De l’oreille absolue à l’oreille affaiblie, nous percevons tous, les sons d’une manière différente et individuelle.</p>" +
        "<p>Ces préférences d'écoute sont essentielles à prendre en considération lorsque nous envisageons l’achat d’une technologie acoustique ou auditive, qu'elle soit pour écouter de la musique ou pour mieux percevoir le monde qui nous entoure.</p>" +
        "<p>Cette recherche de personnalisation du son à votre profil d’audition unique, guide SONOLY au quotidien dans la sélection de produits.</p>"
    },
    {
      id: "customized_noise_suppression",
      title: "Une aide auditive, comment ça marche?",
      value: "<p>L’objectif principal d’une technologie auditive est d’analyser l’environnement sonore et d’amplifier uniquement les signaux de parole pertinents, en réduisant plus ou moins précisément les bruits indésirables.</p>" +
        "<p>Une autre définition, plus scientifique , est que la finalité d’une aide auditive est de traiter le son, en volume et en bruits, afin d’envoyer au cerveau le signal le plus clair possible, réduisant ainsi significativement l’effort d’écoute.</p>",
    },
    {
      id: "voice_assistant_manual_selection",
      title: "Comprendre les différentes technologies auditives.",
      value: "<p>Quelles aides auditives sont faites pour vous?</p>" +
        "<p>S’il existe plusieurs formes et différentes options de connectivité ou de rechargeabilité, qui répondent à des attentes esthétiques ou pratiques particulières, bien choisir ses aides auditives, c’est avant tout choisir le niveau de personnalisation souhaité, la précision d’analyse et de traitement de l’environnement sonore.&nbsp;</p>" +
        "<p>Vos experts SONOLY sont là pour vous accompagner dans cette démarche.</p>",
    },
    {
      id: "iot",
      title: "L'équipe SONOLY",
      value: "<p>Comprendre SONOLY c'est avant tout comprendre l’histoire d’une filiation et d'une transmission de savoir faire entre Gilles Coscas, expert des technologies acoustiques depuis près de 40 ans et sa fille Vanessa.</p>" +
        "<p>En créant SONOLY, Vanessa conserve le même désir initial, le même esprit pionnier tout en renouvelant profondément le marché de l'audition et du son aussi bien au niveau des propositions-produits que de l'environnement et de l'expérience d'achat.</p>" +
        "<p>Accompagnée par deux experts métiers reconnus Jonathan et Raphaël, l'équipe SONOLY promet d'ouvrir de nouvelles perspectives et de placer la technologie au service de l’Homme.</p>",
    },
    {
      id: "enhanced_smart_applications",
      title: "Les prises en charges financières",
      value: "<p>Grâce à la réforme 100%, bénéficiez d’une prise en charge complète ou partielle de votre équipement auditif, en fonction du niveau de performance choisi.</p>" +
        "<p>Demandez&nbsp; plus d'informations à votre expert SONOLY.</p>",
    }
  ]
}
