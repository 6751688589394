import React from "react";
import cx from "classnames";
import uniqid from "uniqid";
import { Typography as TypographyType } from "constants/enums/typography";
import { Color } from "constants/enums/color";
import Typography from "components/commons/Typography/Typography";
import "./Input.scss";

type InputProps = {
  className?: string;
  label?: string;
  errors?: string[];
} & React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

type InputType = React.ForwardRefExoticComponent<
  React.PropsWithoutRef<InputProps>
>;

/**
 * Input Functional Component
 * Use forward ref for compatibility w/ React Hook Form
 * @param {string} className - used to set a class on a higher element tag
 * @param errors
 * @param label
 * @param rest
 * @constructor
 * @return {React.FC<InputProps>}
 */
const Input: InputType = React.forwardRef(
  ({ className, errors, label, ...rest }, ref) => {
    const labels = ["Nom", "Prénom", "Email"];
    const id = rest?.id || uniqid();
    const hasErrors = !!errors?.length;

    const classes: string = cx("input-wrapper", className, {
      "input-wrapper--has-errors": hasErrors,
    });

    return (
      <div className={classes} data-input-name={rest?.name}>
        {label && (
          <label
            htmlFor={id}
            className={
              labels.includes(label)
                ? "input-wrapper__label last-char-asterisk"
                : "input-wrapper__label"
            }
          >
            {label}
          </label>
        )}
        <input
          {...rest}
          /*@ts-ignore*/
          ref={ref}
          id={id}
          className={"input-wrapper__input"}
          aria-invalid={hasErrors}
        />
        {errors && (
          <Typography
            className={"input-wrapper__errors"}
            as={TypographyType.Small}
            color={Color.Error}
            role={"alert"}
          >
            {errors?.map((error) => (
              <span key={id + error}>
                {error}
                <br />
              </span>
            ))}
          </Typography>
        )}
      </div>
    );
  }
);

export default React.memo(Input);
