import * as H              from "history";
import {useHistory}        from "react-router";
import {AnonymousFunction} from "constants/types/anonymousFunction";

export function useHistoryBack(): AnonymousFunction {
  // @ts-ignore
  const history: H.History<H.LocationState> = useHistory();
  return function () {
    // @ts-ignore
    history.goBack();
  }
}
