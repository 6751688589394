import React                          from "react";
import cx                             from "classnames";
import Typography                     from "components/commons/Typography/Typography";
import "./ExperienceTourVirtualVideo.scss";
import {Typography as TypographyType} from "../../../../constants/enums/typography";

type ExperienceTourVirtualVideoProps = {
  className?: string,
}

/**
 * ExperienceTourVirtualVideo Functional Component
 * @param {string} className - used to set a class on a higher element tag
 * @return {React.FC<ExperienceTourVirtualVideoProps>}
 */
const ExperienceTourVirtualVideo: React.FC<ExperienceTourVirtualVideoProps> = ({className}) => {

  const classes: string = cx(
    "experience-tour-virtual-video",
    className,
  );

  return (
    <div className={classes}>
      <Typography as={TypographyType.Subheading}>
        Vivez une expérience unique, <br/>
        immergez-vous à l'intérieur d'une oreille.
      </Typography>
      <ul>
        <li><Typography>1 Insérez le casque de réalité virtuelle.</Typography></li>
        <li><Typography>2 Sélectionnez l'expérience 360 degrés.</Typography></li>
        <li><Typography>3 Restez immobile.</Typography></li>
        <li><Typography>4 Tournez la tête dans toutes les directions.</Typography></li>
      </ul>
    </div>
  );
};

export default ExperienceTourVirtualVideo;
