import React                          from "react";
import cx                             from "classnames";
import {useTranslation}               from "react-i18next";
import Button                         from "components/commons/Button/Button";
import Typography                     from "components/commons/Typography/Typography";
import {LocaleNamespace}              from "config/intl/helpers";
import {RoutePath}                    from "constants/enums/routePath";
import {Size}                         from "constants/enums/size";
import {Typography as TypographyType} from "constants/enums/typography";
import {useRedirect}                  from "hooks/specific/useRedirect";
import { usePatientContext }          from "contexts/global/patient";
import "./ScheduleWelcome.scss";

type ScheduleWelcomeProps = {
  className?: string,
}

/**
 * ScheduleWelcome Functional Component
 * @param {string} className - used to set a class on a higher element tag
 * @constructor
 * @return {React.FC<ScheduleWelcomeProps>}
 */
const ScheduleWelcome: React.FC<ScheduleWelcomeProps> = () => {
  const { t } = useTranslation<LocaleNamespace>(LocaleNamespace.Pages);
  const redirect = useRedirect(RoutePath.ScheduleWelcome);
  const { patientState } = usePatientContext()

  const classes: string = cx(
    "schedule-welcome",
  );

  function handleClick() {
    redirect(RoutePath.ScheduleCalendar);
  }

  return (
    <div className={classes}>
      <Typography as={TypographyType.Title} className={"schedule-welcome__title"}>
        {t("schedule.welcome.title", { firstname: patientState.firstname || "inconnu" })}
      </Typography>
      {/*<Typography as={TypographyType.Subtitle} className={"schedule-welcome__subtitle"}>*/}
      {/*  {t("schedule.welcome.subtitle")}*/}
      {/*</Typography>*/}
      <Button size={Size.Large} className={"schedule-welcome__btn"} onClick={handleClick}>
        {t("schedule.welcome.btn")}
      </Button>
    </div>
  );
};

export default ScheduleWelcome;
