import cx from "classnames";
import Button from "components/commons/Button/Button";
import HookFormControl from "components/containers/Forms/HookFormControl/HookFormControl";
import { RoutePath } from "constants/enums/routePath";
import { Size } from "constants/enums/size";
import { useCatchApiErrors } from "hooks/specific/useCatchApiErrors";
import { useRedirect } from "hooks/specific/useRedirect";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { RouteComponentProps } from "react-router";
import { toast } from "react-toastify";
import API from "services/api";
import TokenService from "services/token";
import { menuItems } from "../utils/DashboardMenuItems";
import "./Dashboard.scss";

interface Dashboard {
  className?: string;
}

/**
 * Dashboard Functional Component
 * @param {string} className - used to set a class on a higher element tag
 * @param location
 * @constructor
 * @return {React.FC<Dashboard>}
 */
const Dashboard: React.FC<Dashboard & RouteComponentProps> = ({
  className,
}) => {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({ criteriaMode: "all" });
  const apiErrorsHandler = useCatchApiErrors();
  const [openConfirmLogin, setOpenConfirmLogin] = useState(false);
  const redirect = useRedirect();

  const onSubmit = async (values: { password: string }) => {
    const toastId = toast.loading("Vérification en cours ...");
    const userEmail = TokenService.getUserEmail();

    try {
      if (!userEmail)
        return toast.error("Cannot found user email, please reconnect");

      await API.postLoginCheck({
        username: userEmail,
        password: values.password,
      });
      toast.dismiss(toastId);

      if (!window.open("https://app.sonoly.fr"))
        return toast.warn(
          "Impossible d'ouvrir la fenêtre, veuillez autoriser les pop-ups."
        );

      reset();
      setOpenConfirmLogin(false);
    } catch (error: any) {
      apiErrorsHandler(error, toastId);
    }
  };

  const classes: string = cx("dashboard", className);

  return (
    <div className={classes}>
      {menuItems.map((menuItem) => (
        <button
          key={menuItem.key}
          data-key={menuItem.key}
          className={"dashboard__btn"}
          onClick={() => redirect(RoutePath.HearingTestWelcome)}
        >
          <span className={"dashboard__btn-text"}>
            {menuItem.untranslatedTitle}
          </span>
        </button>
      ))}

      {openConfirmLogin && (
        <div
          className="modal-layer"
          onClick={(ev) => {
            if (ev.target === ev.currentTarget) {
              setOpenConfirmLogin(false);
            }
          }}
        >
          <div className="confirm-modal">
            <form className="login__form" onSubmit={handleSubmit(onSubmit)}>
              {
                <HookFormControl
                  className="login__form__item"
                  autoFocus={true}
                  data={{
                    name: "password",
                    type: "password",
                    className: "login__form__item__input-password",
                    label: "Mot de passe",
                    autocomplete: "off",
                    required: {
                      value: true,
                      message: "Password is required !",
                    },
                  }}
                  handleRegister={register}
                  errors={errors}
                />
              }

              <div className="login__form__item">
                <Button type={"submit"} size={Size.Large}>
                  Confimer
                </Button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
