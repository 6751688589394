import React           from "react";
import ReactDOM        from "react-dom";
import App             from "components/containers/App/App";
import AuthProvider    from "contexts/global/AuthContext";
import reportWebVitals from "./reportWebVitals";
import "config/intl"; // Internationalization w/ i18next library
import "./index.scss";
import "react-toastify/dist/ReactToastify.css";
import { setDefaultConfig } from "antd-mobile";
import { base } from "antd-mobile/es/locales/base";

setDefaultConfig({
  locale: {
    ...base,
    common: {
      confirm: 'Confirmer',
      cancel: 'Fermer'
    },
    Calendar: {
      markItems: ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'],
      renderYearAndMonth: (year, month) => `${month}/${year}`
    },  
  }
})

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <App/>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
