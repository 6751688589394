import { AxiosError } from "axios";
import apiAxiosInstance from "./config/axios";
import routes, { buildQueryParams } from "./config/routes";
import NetworkException from "./exceptions/NetworkException";
import UnprocessableEntityException from "./exceptions/UnprocessableEntityException";
import UnauthorizedException from "./exceptions/UnauthorizedException";
import UnknownException from "./exceptions/UnknownException";
import { Laboratory, LaboratoryAttendance, Patient, Schedule } from "@audiowizard/common";
import {
  IPostLoginCheckData, IViolation, TGetLaboratoriesFunc,
  TGetPatientsFunc, TPostLoginCheckFunc, TPostPatientsFunc,
  EnumResponseHttpStatus, TGetLaboratoriesAvailableSlotsFunc,
  IGetLaboratoriesAvailableSlotsData, TPostShedulesFunc, 
  TPostHearingTestsFunc, IPostHearingTestsData, TGetLaboratoryAttendanceFunc, 
  IPostRefreshTokenData, TPostRefreshTokenFunc,
} from "./types";

const handleErrors = (error: AxiosError) => {
  if (error.response === undefined) {
    throw new NetworkException(
      "The server could not respond to the request",
      error.config.baseURL,
      error.config.method,
      error.config.url
    );
  }

  const responseStatus = error.response.status;

  switch (responseStatus) {
    case EnumResponseHttpStatus.UnprocessableEntity:
      throw new UnprocessableEntityException(
        "The request cannot be processed.",
        (error.response.data as { violations: IViolation[] }).violations
      );
    case EnumResponseHttpStatus.Unauthorized:
      throw new UnauthorizedException(
        "Invalid credentials."
      );
    default:
      throw new UnknownException(
        "Unknown error exception.",
        error,
      )
  }

};

const postLoginCheck: TPostLoginCheckFunc = (payload) => {
  const request = apiAxiosInstance
    .post<IPostLoginCheckData>(
      routes.login_check,
      payload
    );

  return request
    .then(response => response.data)
    .catch(error => handleErrors(error));
};

const postPatients: TPostPatientsFunc = (payload) => {
  const request = apiAxiosInstance
    .post<Patient>(
      routes.patients,
      payload
    );

  return request
    .then(response => response.data)
    .catch(error => handleErrors(error));
};

const getPatients: TGetPatientsFunc = (queryParams) => {
  const url = queryParams !== undefined
    ? routes.patients + buildQueryParams(routes.patients, queryParams)
    : routes.patients;

  const request = apiAxiosInstance.get<Patient[]>(url);

  return request
    .then(response => response.data)
    .catch(error => handleErrors(error));
};

const getLaboratories: TGetLaboratoriesFunc = () => {
  const request = apiAxiosInstance.get<Laboratory[]>(routes.laboratories)

  return request
    .then(response => response.data)
    .catch(error => handleErrors(error));
}

const getLaboratoriesAvailableSlots: TGetLaboratoriesAvailableSlotsFunc = (payload) => {
  const request = apiAxiosInstance.post<IGetLaboratoriesAvailableSlotsData>(
    routes.laboratories_available_slots,
    payload
  )

  return request
    .then(response => response.data)
    .catch(error => handleErrors(error))
}

const postSchedules: TPostShedulesFunc = (payload) => {
  const request = apiAxiosInstance.post<Schedule>(
    routes.schedules,
    payload
  )

  return request
    .then(response => response.data)
    .catch(error => handleErrors(error))
}

const postHearingTests: TPostHearingTestsFunc = (payload) => {
  const request = apiAxiosInstance.post<IPostHearingTestsData>(
    routes.hearing_tests,
    payload
  )

  return request
    .then(response => response.data)
    .catch(error => handleErrors(error))
}

const getLaboratoryAttendance: TGetLaboratoryAttendanceFunc = (queryParams) => {
  const url = queryParams !== undefined
  ? routes.laboratory_attendances + buildQueryParams(routes.laboratory_attendances, queryParams)
  : routes.laboratory_attendances;

  const request = apiAxiosInstance.get<LaboratoryAttendance[]>(url)

  return request
    .then(response => response.data)
    .catch(error => handleErrors(error))
}

const postRefreshToken: TPostRefreshTokenFunc = (payload) => {
  const request = apiAxiosInstance.post<IPostRefreshTokenData>(
    routes.refresh_token,
    payload
  )

  return request
    .then(response => response.data)
    .catch(error => handleErrors(error))
}
const API = {
  postLoginCheck,
  postPatients,
  postSchedules,
  getPatients,
  getLaboratories,
  getLaboratoriesAvailableSlots,
  getLaboratoryAttendance,
  postHearingTests,
  postRefreshToken
}

export default API;
