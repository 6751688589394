import React, { useRef } from "react";
import { useLocation } from "react-router-dom";
import cx from "classnames";
import { ReactComponent as CheckIcon } from "assets/img/svg/check.svg";
import Typography from "../../../../components/commons/Typography/Typography";
import { Typography as TypographyType } from "../../../../constants/enums/typography";
import { ReactComponent as PrimeLogo } from "../../../../assets/img/svg/prime.svg";
import "./ProtectionDiscovery.scss";
import AudioButton from "../../../../components/commons/AudioButton/AudioButton";

type ProtectionDiscoveryProps = {
  className?: string,
}

/**
 * ProtectionDiscovery Functional Component
 * @param {string} className - used to set a class on a higher element tag
 * @return {React.FC<ProtectionDiscoveryProps>}
 */
const ProtectionDiscovery: React.FC<ProtectionDiscoveryProps> = ({ className }) => {
  const { state: { volume } } = useLocation();
  const audioButtonsRef = useRef<any[]>([]);
  const currentIdPlayingRef = useRef<null | number>(null);
  const audioFilePrePath = `/assets/audio/product-test/discovery/protection`;

  const classes: string = cx(
    "protection-discovery",
    className,
  );

  function handlePlay(id: number) {
    if (typeof currentIdPlayingRef.current === "number") {
      audioButtonsRef.current[currentIdPlayingRef.current].stop();
    }
    currentIdPlayingRef.current = id
  }

  return (
    <div className={classes}>
      <div className="protection-discovery__cols">
        <div className="protection-discovery__col" />
        <div className="protection-discovery__col">
          <Typography as={TypographyType.Title} className="protection-discovery__col-title">
            Protections auditives <br />
            standard
          </Typography>
          <Typography as={TypographyType.Body} className="protection-discovery__col-subtitle">
            Les protections auditives standard représentent la solution la plus économique pour
            préserver votre audition. En l'absence de filtre acoustique spécifique, l'attenuation
            sera plus marquée sur les tonalités aigües.
          </Typography>
        </div>
        <div className="protection-discovery__col">
          <Typography as={TypographyType.Title} className="protection-discovery__col-title">
            Protections auditives <br />
            <PrimeLogo className="protection-discovery__prime-logo" /> haute fidélité
          </Typography>
          <Typography as={TypographyType.Body} className="protection-discovery__col-subtitle">
            Grâce à un filtre acoustique spécifiquement conçu pour les protections auditives
            haute fidélité, profitez d'une attenuation sonore équilibrée quelque soit la tonalité
            du son. Protéger votre audition et percevez l’environnement sonore avec une restitution
            fidèle, sans déformation.
          </Typography>
        </div>
        <div className="protection-discovery__sounds">
          <div className="protection-discovery__sounds-standard">
            <div className="protection-discovery__sounds-col">
              <Typography className="protection-discovery__sounds-title">
                <CheckIcon />
                Simuler <b>Au concert</b>
              </Typography>
              <AudioButton ref={(el: any) => audioButtonsRef.current[0] = el} url={`${audioFilePrePath}/SONOLY_RollingStones_Standard.wav`} onClick={() => handlePlay(0)} volume={volume} />
              <Typography className="protection-discovery__sounds-subtitle">
                Protection Standard
              </Typography>
            </div>
            <div className="protection-discovery__sounds-col">
              <Typography className="protection-discovery__sounds-title">
                  &nbsp;
              </Typography>
              <AudioButton ref={(el: any) => audioButtonsRef.current[1] = el} url={`${audioFilePrePath}/SONOLY_RollingStones_Base.wav`} onClick={() => handlePlay(1)} volume={volume} />
              <Typography className="protection-discovery__sounds-subtitle">
                Sans Protection
              </Typography>
            </div>
            <div className="protection-discovery__sounds-col">
              <Typography className="protection-discovery__sounds-title">
                <CheckIcon />
                Simuler <b>À la gare</b>
              </Typography>
              <AudioButton ref={(el: any) => audioButtonsRef.current[2] = el} url={`${audioFilePrePath}/SONOLY_Gare_Standard.wav`} onClick={() => handlePlay(2)} volume={volume} />
              <Typography className="protection-discovery__sounds-subtitle">
                Protection Standard
              </Typography>
            </div>
            <div className="protection-discovery__sounds-col">
              <Typography className="protection-discovery__sounds-title">
                  &nbsp;
              </Typography>
              <AudioButton ref={(el: any) => audioButtonsRef.current[3] = el} url={`${audioFilePrePath}/SONOLY_Gare_Base.wav`} onClick={() => handlePlay(3)} volume={volume} />
              <Typography className="protection-discovery__sounds-subtitle">
                Sans Protection
              </Typography>
            </div>
          </div>
          <div className="protection-discovery__sounds-hd">
            <div className="protection-discovery__sounds-col">
              <Typography className="protection-discovery__sounds-title">
                <CheckIcon />
                Simuler <b>Au concert</b>
              </Typography>
              <AudioButton ref={(el: any) => audioButtonsRef.current[4] = el} url={`${audioFilePrePath}/SONOLY_RollingStones_HD.wav`} onClick={() => handlePlay(4)} volume={volume} />
              <Typography className="protection-discovery__sounds-subtitle">
                Protection HD
              </Typography>
            </div>
            <div className="protection-discovery__sounds-col">
              <Typography className="protection-discovery__sounds-title">
                 &nbsp;
              </Typography>
              <AudioButton ref={(el: any) => audioButtonsRef.current[5] = el} url={`${audioFilePrePath}/SONOLY_RollingStones_Base.wav`} onClick={() => handlePlay(5)} volume={volume} />
              <Typography className="protection-discovery__sounds-subtitle">
                Sans Protection
              </Typography>
            </div>
            <div className="protection-discovery__sounds-col">
              <Typography className="protection-discovery__sounds-title">
                <CheckIcon />
                Simuler <b>À la gare</b>
              </Typography>
              <AudioButton ref={(el: any) => audioButtonsRef.current[6] = el} url={`${audioFilePrePath}/SONOLY_Gare_HD.wav`} onClick={() => handlePlay(6)} volume={volume} />
              <Typography className="protection-discovery__sounds-subtitle">
                Protection HD
              </Typography>
            </div>
            <div className="protection-discovery__sounds-col">
              <Typography className="protection-discovery__sounds-title">
                &nbsp;
              </Typography>
              <AudioButton ref={(el: any) => audioButtonsRef.current[7] = el} url={`${audioFilePrePath}/SONOLY_Gare_Base.wav`} onClick={() => handlePlay(7)} volume={volume} />
              <Typography className="protection-discovery__sounds-subtitle">
                Sans Protection
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProtectionDiscovery;
