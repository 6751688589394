import React               from "react";
import cx                  from "classnames";
import videojs             from "video.js";
import "video.js/dist/video-js.css";
import "videojs-youtube/dist/Youtube.min";
import {AnonymousFunction} from "constants/types/anonymousFunction";
import "./Video.scss";
import {ReactComponent as CloseIcon}    from "assets/img/svg/close.svg";

type VideoProps = {
  className?: string,
  options?: videojs.PlayerOptions,
  volume?: number,
  onClose?: AnonymousFunction,
  onReady?: (player: videojs.Player) => void,
}

/**
 * Video Functional Component
 * @param {string} className - used to set a class on a higher element tag
 * @param options
 * @param onReady
 * @return {React.FC<VideoProps>}
 */
const Video: React.FC<VideoProps> = ({className, options, volume, onClose, onReady}) => {
  const videoRef  = React.useRef<HTMLVideoElement | null>(null);
  const playerRef = React.useRef<videojs.Player | null>(null);

  const classes: string = cx(
    "video",
    className,
  );

  React.useEffect(() => {
    // make sure Video.js player is only initialized once
    if (!playerRef.current) {
      const videoElement = videoRef.current;
      if (!videoElement) return;

      const player = playerRef.current = videojs(videoElement, options, () => {
        onReady && onReady(player);
      });

      player.volume(volume ?? 1)
    }
    else {
      // you can update player here [update player through props]
      // const player = playerRef.current;
      // player.autoplay(options.autoplay);
      // player.src(options.sources);
    }
  }, [options]);

  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = null;
      }
    };
  }, []);

  return (
    <>
      {onClose && <CloseIcon className="video__close-icon" role="close-btn" onClick={onClose}/>}
      <div data-vjs-player className={classes}>
        <video ref={videoRef} className="video-js vjs-big-play-centered"/>
      </div>
    </>
  );
};

export default Video;
