import React, { createContext, useContext, useReducer } from "react";
import { init, initialState, reducer } from "./reducer";
import { Context, Reducer, State } from "./types";

const PatientContext = createContext<Context | undefined>(undefined);

function PatientProvider({
  children,
}: {
  children: React.ReactChild;
}): React.ReactElement {
  const [state, dispatch] = useReducer<Reducer, State>(
    reducer,
    initialState,
    init
  );

  return (
    <PatientContext.Provider
      value={{
        patientState: state,
        patientDispatch: dispatch,
      }}
    >
      {children}
    </PatientContext.Provider>
  );
}

function usePatientContext(): Context {
  const context = useContext<Context | undefined>(PatientContext);

  if (context === undefined) {
    throw new Error("usePatientContext must be used within a PatientProvider");
  }

  return context;
}

export { PatientProvider, usePatientContext };
