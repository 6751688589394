import { ReactComponent as PauseIcon } from "assets/img/svg/pause_btn.svg";
import { ReactComponent as PlayIcon } from "assets/img/svg/play_btn.svg";
import cx from "classnames";
import Button from "components/commons/Button/Button";
import { Direction } from "constants/enums/direction";
import { hearingTestViewOrder } from "constants/enums/routePath";
import { Shape } from "constants/enums/shape";
import { Size } from "constants/enums/size";
import { useMount } from "hooks/global/useMount";
import { useOscillation } from "hooks/specific/useOscillation";
import { useRedirect } from "hooks/specific/useRedirect";
import React from "react";
import { useLocation } from "react-router-dom";

interface IActivePlayer {
  className?: string;
  frequency?: number;
  dbPreset: number;
  soundPosition?: Direction;
  onClick: (flag: boolean) => void;
  onChange?: (dB: number) => void;
}

export const minDb = -60;
export const maxDb = 40;
export const chunkBase = 10;

export const ActivePlayer: React.FC<IActivePlayer> = ({
  className,
  frequency = 1000,
  soundPosition,
  dbPreset,
  onClick,
  onChange,
}) => {
  const config = {
    // TODO: externalize this configuration to parent
    frequency: {
      preset: frequency,
      minDb: minDb,
      maxDb: maxDb,
    },
    decibels: {
      preset: dbPreset,
      chunkBase: chunkBase,
    },
    soundPosition: {
      preset: {
        both: !soundPosition,
        left: soundPosition === Direction.Left,
        right: soundPosition === Direction.Right,
      },
    },
  };
  const { decibels } = useOscillation(config);
  const redirect = useRedirect();
  const { pathname } = useLocation();

  const classes = cx("active-player", className);

  /**
   * TODO: Wrap it in an unmount props
   */
  useMount(() => {
    if (onChange) onChange(config.decibels.preset);
  });

  /**
   * Handle minus bouton click
   */
  // function _handleMinusClick() {
  //   const evaluated = decibels.getValue() - config.decibels.chunkBase;
  //   if (evaluated < config.frequency.minDb) return;

  //   decibels.down();
  //   if (onChange) onChange(decibels.getValue());
  // }

  /**
   * Handle user click on Pause button
   * @param event
   */ // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function _handlePauseClick(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    onClick(false);
  }

  /**
   * Handle plus bouton click
   */
  function _handlePlusClick() {
    const evaluated = decibels.getValue() + config.decibels.chunkBase;
    if (evaluated > config.frequency.maxDb) return;

    decibels.up();
    if (onChange) onChange(decibels.getValue());
  }

  function _handleRedirect() {
    if (hearingTestViewOrder[pathname]?.next)
      redirect(hearingTestViewOrder[pathname].next as string);
  }

  return (
    <div className={classes}>
      <Button
        className="btn__back"
        shape={Shape.Circled}
        onClick={_handlePlusClick}
      >
        Je n'entends pas
      </Button>
      <Button
        shape={Shape.Circled}
        size={Size.Large}
        onClick={_handlePauseClick}
      >
        <PauseIcon />
      </Button>
      <Button
        className="btn__next"
        shape={Shape.Circled}
        onClick={_handleRedirect}
      >
        J'entends
      </Button>
    </div>
  );
};

interface IOffPlayer {
  onClick: (flag: boolean) => void;
  onChange?: (dB: number) => void;
}

export const OffPlayer: React.FC<IOffPlayer> = ({ onClick, onChange }) => {
  function _handleClick() {
    onClick(true);
    onChange?.(-60);
  }

  return (
    <button onClick={_handleClick}>
      <PlayIcon />
    </button>
  );
};
