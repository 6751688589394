import React   from "react";
import cx      from "classnames";
import {Shape} from "constants/enums/shape";
import {Size}  from "constants/enums/size";
import "./Button.scss";

export type ButtonProps = {
  className?: string,
  size?: Size,
  shape?: Shape,
  invertedColor?: boolean,
  type?: string // TODO
} & React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>

/**
 * Button Functional Component
 * @param {string} className - used to set a class on a higher element tag
 * @param children
 * @param invertedColor
 * @param shape
 * @param size
 * @param args
 * @constructor
 * @return {}
 */
const Button: React.FC<ButtonProps> = ({className, children, invertedColor, shape, size, ...args}) => {

  const classes: string = cx(
    "button",
    className,
    {
      "button--inverted-color": invertedColor,
      "button--small"         : size === Size.Small,
      "button--medium"        : size === Size.Med,
      "button--large"         : size === Size.Large,
      "button--rounded"       : shape === Shape.Rounded,
      "button--circled"       : shape === Shape.Circled,
    }
  );

  return (
    <button {...args} className={classes}>
      {children}
    </button>
  );
};

export default Button;
