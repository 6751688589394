class UnauthorizedException extends Error {
    name: string;
    message: string;

    constructor(message: string) {
        super(message)
        this.name = "UnauthorizedException"
        this.message = message
    }
}

export default UnauthorizedException