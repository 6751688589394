import React                           from "react";
import cx                              from "classnames";
import Accordion                       from "components/commons/Accordion/Accordion";
import {useExpTourQuestionsRessources} from "./ExpTourQuestionsRessources";

type ExpTourQuestionsAccordionProps = {
  className?: string,
}

/**
 * ExpTourQuestionsAccordion Functional Component
 * @param {string} className - used to set a class on a higher element tag
 * @return {React.FC<ExpTourQuestionsAccordionProps>}
 */
const ExpTourQuestionsAccordion: React.FC<ExpTourQuestionsAccordionProps> = ({className}) => {
  const ressources = useExpTourQuestionsRessources();

  const classes: string = cx(
    "exp-tour-questions-accordion",
    className,
  );

  return (
    <Accordion className={classes} keys={ressources}/>
  );
};

export default ExpTourQuestionsAccordion;
