import React                     from "react";
import cx                        from "classnames";
import Cols                      from "components/commons/Cols/Cols";
import AudioButton               from "components/commons/AudioButton/AudioButton";
import Typography                from "components/commons/Typography/Typography";
import "./ExperienceTourVideoChoices.scss";
import {useHistory}              from "react-router";
import {EarTour, ExperienceTourRoutePath} from "../../router/ExperienceTourRoutePath";

type ExperienceTourVideoChoicesProps = {
  className?: string,
}

/**
 * ExperienceTourVideoChoices Functional Component
 * @param {string} className - used to set a class on a higher element tag
 * @return {React.FC<ExperienceTourVideoChoicesProps>}
 */
const ExperienceTourVideoChoices: React.FC<ExperienceTourVideoChoicesProps> = ({className}) => {
  const history         = useHistory();

  const classes: string = cx(
    "experience-tour-video-choices",
    className,
  );

  function handleRedirect(path: string): void {
    history.push(path, {from: location?.pathname});
  }

  return (
    <Cols className={classes}>
      <div className="experience-tour-video-choices__col">
        <AudioButton isStatic onClick={() => handleRedirect(`/product-test/sound-calibration/${EarTour.Video}`)}/>
        <Typography className={"experience-tour-video-choices__text"}>
          Visite de l'oreille
        </Typography>
      </div>
      <div className="experience-tour-video-choices__col">
        <AudioButton isStatic onClick={() => handleRedirect(ExperienceTourRoutePath.VirtualVideo)}/>
        <Typography className={"experience-tour-video-choices__text"}>
          Visite de l'oreille <br/>
          en réalité virtuelle
        </Typography>
      </div>
    </Cols>
  );
};

export default ExperienceTourVideoChoices;
