import React, { forwardRef, useImperativeHandle, useState } from "react";
import cx from "classnames";
import AudioButton from "components/commons/AudioButton/AudioButton";
import Typography from "components/commons/Typography/Typography";
import { Typography as TypographyType } from "constants/enums/typography";
import { ReactComponent as RatingStartLogo } from "assets/img/svg/rating_star.svg";
import { ReactComponent as TVLogo } from "assets/img/svg/tv.svg";
import { ReactComponent as NatureLogo } from "assets/img/svg/nature.svg";
import { ReactComponent as MusicLogo } from "assets/img/svg/music.svg";
import { ReactComponent as BluetoothLogo } from "assets/img/svg/bluetooth.svg";
import { ReactComponent as ChatLogo } from "assets/img/svg/chat.svg";
import { ReactComponent as RestaurantLogo } from "assets/img/svg/restaurant.svg";
import { ReactComponent as PrimeLogo } from "assets/img/svg/prime.svg";
import DiscoveryPanel from "../../Panels/DiscoveryPanel/DiscoveryPanel";
import "./DiscoveryCol.scss";

type DiscoveryColProps = {
  id: string | number,
  isPrime?: boolean,
  className?: string,
  title: string,
  short: string,
  review: {
    quality: number, // between 1 and 5
    idealFor?: {
      tv?: boolean,
      nature?: boolean,
      music?: boolean,
      bluetooth?: boolean,
      conversation?: boolean,
      mealTime?: boolean,
    }
  },
  subtext: string,
  price: number | { // todo: add currency logic
    amount: number,
    currency?: "euro" | "dollar"
  }
  type: "discovery" | "comfort" | "excellence",
  volume?: number, // float, 0 to 1,
  onPanel?: (open: boolean, id: string | number) => void,
}

/**
 * DiscoveryCol Functional Component
 * @param {string} className - used to set a class on a higher element tag
 * @param volume
 * @param price
 * @param review
 * @param subtext
 * @param title
 * @param type
 * @return {React.FC<DiscoveryColProps>}
 */
const DiscoveryCol: React.FC<DiscoveryColProps> = forwardRef(({
  id,
  isPrime,
  className,
  onPanel,
  volume,
  price,
  review,
  subtext,
  title,
  short,
  type
}, ref: any) => {
  const [showPanel, setShowPanel] = useState<boolean | null>(null);

  const classes: string = cx(
    "discovery-col",
    className,
  );

  // FIXME: quick correction for multi panels opened in the same time
  useImperativeHandle(ref, () => ({
    id,
    close: () => {
      if (showPanel) setShowPanel(false);
    }
  }));

  /**
   * Build the five rating star icons and determine if selected
   */
  function buildRatingStars(): JSX.Element[] {
    return [...Array(5)].map((e, i) => (
      <RatingStartLogo
        className={
          cx("discovery-col__rating-star", { "discovery-col__rating-star--selected": (i + 1) <= review.quality })
        }
        key={i}
      />
    ));
  }

  function buildIdealFor() {
    return (
      <>
        <TVLogo data-selected={review?.idealFor?.tv} />
        <NatureLogo data-selected={review?.idealFor?.nature} />
        <MusicLogo data-selected={review?.idealFor?.music} />
        <BluetoothLogo data-selected={review?.idealFor?.bluetooth} />
        <ChatLogo data-selected={review?.idealFor?.conversation} />
        <RestaurantLogo data-selected={review?.idealFor?.mealTime} />
      </>
    );
  }

  function handleDiscoverTest() {
    setShowPanel(true);
    if (onPanel) onPanel(true, id);
  }

  function handlePanelClose() {
    setShowPanel(false);
    if (onPanel) onPanel(false, id);
  }

  return (
    <div className={classes}>
      <Typography as={TypographyType.Title} className="discovery-col__title">
        {isPrime && <PrimeLogo className="discovery-col__prime-logo" />}
        {title}
      </Typography>
      <div className="discovery-col__review">
        <span className="discovery-col__review-quality">
          qualité audio
          <span className="discovery-col__review-stars">{buildRatingStars()}</span>
        </span>
        {
          review?.idealFor &&
          <span className="discovery-col__review-ideal-for" data-text="idéal pour">{buildIdealFor()}</span>
        }
      </div>
      <AudioButton isStatic invertedColor onClick={handleDiscoverTest} />
      <Typography className="discovery-col__subtext">{subtext}</Typography>
      <Typography as={TypographyType.Title} className="discovery-col__price">{price}€</Typography>
      <DiscoveryPanel
        className="discovery-col__audio-panel"
        open={!!showPanel}
        onClose={handlePanelClose}
        pack={short}
        type={type}
        volume={volume}
      />
    </div>
  );
});

export default DiscoveryCol;
