import { Direction } from "constants/enums/direction";
import { ActionReducer } from "constants/interfaces/actionReducer";
import {
  SET_AGE_RANGE,
  SET_GLOBAL_HEARING_SENSATION, SET_HIGH_PITCHED_SOUND_HEARING_SENSATION,
  SET_MUMBLING_HEARING_SENSATION,
  SET_NOISY_ENVIRONMENT_CONVERSATION_HEARING_SENSATION,
  SET_PHONE_HEARING_SENSATION,
  SET_TONAL_AUDIOMETRY_RESULT,
  RESET_HEARING_TEST,
  SET_SCORES
} from "../actionTypes";
import { State } from "../interfaces";


/**
 * Initial state
 * Important: set to null to significate an unmodified value
 */
const initialState: State = {
  hearingSensations: {
    global: null,
    mumbling: null,
    phone: null,
    highPitchedSound: null,
    noisyEnvironmentConversation: null,
  },
  tonalAudiometryResults: {
    leftEar: {
      1000: null,
      2000: null,
      4000: null,
    },
    rightEar: {
      1000: null,
      2000: null,
      4000: null,
    }
  },
  scores: {
    leftEar: null,
    rightEar: null,
    average: null,
  }
};

export const InitialState = { ...initialState }

/**
 * Reducer
 * @param state
 * @param action
 */ // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function reducer(state: State, action: ActionReducer) {
  switch (action.type) {
    case SET_GLOBAL_HEARING_SENSATION:
      return { ...state, hearingSensations: { ...state?.hearingSensations, global: action.payload } };

    case SET_TONAL_AUDIOMETRY_RESULT: {
      const { soundPosition, frequency, decibels } = action.payload;

      let soundPositionKey: string;
      if (soundPosition === Direction.Right) soundPositionKey = "rightEar";
      else if (soundPosition === Direction.Left) soundPositionKey = "leftEar";
      else throw new Error("soundPosition needs to be type of Direction enum");

      return {
        ...state,
        tonalAudiometryResults: {
          ...state?.tonalAudiometryResults,
          [soundPositionKey]: { // @ts-ignore (Cf. https://stackoverflow.com/questions/57086672/element-implicitly-has-an-any-type-because-expression-of-type-string-cant-b)
            ...state?.tonalAudiometryResults[soundPositionKey],
            [frequency]: decibels,
          }
        }
      };
    }

    case SET_AGE_RANGE:
      return { ...state, ageRange: action?.payload };

    case SET_MUMBLING_HEARING_SENSATION:
      return { ...state, hearingSensations: { ...state?.hearingSensations, mumbling: action.payload } };

    case SET_PHONE_HEARING_SENSATION:
      return { ...state, hearingSensations: { ...state?.hearingSensations, phone: action.payload } };

    case SET_HIGH_PITCHED_SOUND_HEARING_SENSATION:
      return { ...state, hearingSensations: { ...state?.hearingSensations, highPitchedSound: action.payload } };

    case SET_NOISY_ENVIRONMENT_CONVERSATION_HEARING_SENSATION:
      return { ...state, hearingSensations: { ...state?.hearingSensations, noisyEnvironmentConversation: action.payload } };

    case RESET_HEARING_TEST:
      console.debug(initialState)
      return {
        ...initialState,
        tonalAudiometryResults: {
          leftEar: {
            1000: null,
            2000: null,
            4000: null,
          },
          rightEar: {
            1000: null,
            2000: null,
            4000: null,
          }
        },
      }

    case SET_SCORES: {
      return {
        ...state,
        scores: action.payload
      }
    }

    default:
      return state;
  }
}
