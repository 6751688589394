import React                        from "react";
import ProductTestSoundCalibration  from "../views/Calibration/ProductTestSoundCalibration";
import ProductTestChoices           from "../views/Choices/ProductTestChoices";
import ProductTestDeviceChoices     from "../views/DeviceChoices/ProductTestDeviceChoices";
import ProductTestHeadsetChoices    from "../views/HeadsetChoices/ProductTestHeadsetChoices";
import ProductTestDiscovery         from "../views/ProductTestDiscovery/ProductTestDiscovery";
import {ProductTestRoutePath}       from "features/product-test/router/ProductTestRoutePath";
import ProtectionDiscovery          from "../views/ProtectionDiscovery/ProtectionDiscovery";
import HeadsetVideo               from "../views/HeadsetVideo/HeadsetVideo";
import ProtectionDiscoveryDetails from "../views/ProtectionDiscoveryDetails/ProtectionDiscoveryDetails";

export interface IProductTestRoutes {
  path: string
  component:  React.FC
  authed: boolean
 }

const ProductTestRoutes = [
  {authed: false, path: ProductTestRoutePath.Choices, component: ProductTestChoices},
  {authed: false, path: ProductTestRoutePath.SoundCalibration, component: ProductTestSoundCalibration},
  {authed: false, path: ProductTestRoutePath.HeadsetChoices, component: ProductTestHeadsetChoices},
  {authed: false, path: ProductTestRoutePath.DeviceChoices, component: ProductTestDeviceChoices},
  {authed: false, path: ProductTestRoutePath.ProductDiscovery, component: ProductTestDiscovery},
  {authed: false, path: ProductTestRoutePath.ProtectionDiscovery, component: ProtectionDiscovery},
  {authed: false, path: ProductTestRoutePath.ProtectionDiscoveryDetails, component: ProtectionDiscoveryDetails},
  {authed: false, path: ProductTestRoutePath.HeadsetVideo, component: HeadsetVideo},
];

export default ProductTestRoutes;
