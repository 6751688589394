import React from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import Button from "components/commons/Button/Button";
import Typography from "components/commons/Typography/Typography";
import { LocaleNamespace } from "config/intl/helpers";
import { RoutePath } from "constants/enums/routePath";
import { Size } from "constants/enums/size";
import { Typography as TypographyType } from "constants/enums/typography";
import { useRedirect } from "hooks/specific/useRedirect";
import { usePatientContext } from "contexts/global/patient";
import "./HearingTestWelcome.scss";

type HearingTestWelcomeProps = {
  className?: string;
};

/**
 * HearingTestWelcome Functional Component
 * @param {string} className - used to set a class on a higher element tag
 * @constructor
 * @return {React.FC<HearingTestWelcomeProps>}
 */
const HearingTestWelcome: React.FC<HearingTestWelcomeProps> = ({
  className,
}) => {
  const { t } = useTranslation<LocaleNamespace>(LocaleNamespace.Pages);
  const redirect = useRedirect();
  const { patientState } = usePatientContext();

  const classes: string = cx("hearing-test-welcome", className);

  function handleClick() {
    redirect(RoutePath.HearingTestSetupInstructions);
    console.log("test");
  }

  return (
    <div className={classes}>
      <Typography
        as={TypographyType.Title}
        className={"hearing-test-welcome__title"}
      >
        {t("hearing-test.welcome.title", { firstname: patientState.firstname })}
      </Typography>
      <Typography
        as={TypographyType.Body}
        className={"hearing-test-welcome__subtitle"}
      >
        {t("hearing-test.welcome.subtitle")}
      </Typography>
      <Button
        size={Size.Large}
        className={"hearing-test-welcome__btn"}
        onClick={handleClick}
      >
        {t("hearing-test.welcome.btn")}
      </Button>
    </div>
  );
};

export default HearingTestWelcome;
