import React, {useState}              from "react";
import cx                             from "classnames";
import "./ProtectionDiscoveryDetails.scss";
import Typography                     from "../../../../components/commons/Typography/Typography";
import {Typography as TypographyType} from "../../../../constants/enums/typography";
import AudioButton                    from "../../../../components/commons/AudioButton/AudioButton";
import DiscoveryPanel                 from "../../containers/Panels/DiscoveryPanel/DiscoveryPanel";
import {useLocation}                  from "react-router-dom";
import BackgroundImage from "assets/img/backgrounds/product-test-protection-details.png"

type ProtectionnDiscoveryDetailsProps = {
  className?: string,
}

/**
 * ProtectionnDiscoveryDetails Functional Component
 * @param {string} className - used to set a class on a higher element tag
 * @return {React.FC<ProtectionnDiscoveryDetailsProps>}
 */
const ProtectionDiscoveryDetails: React.FC<ProtectionnDiscoveryDetailsProps> = ({className}) => {
  const [showPanel, setShowPanel] = useState<boolean | null>(null);
  const { state: locationState }  = useLocation();

  const classes: string = cx(
    "protection-discovery-details",
    className,
  );

  function handleDiscoverTest() {
    setShowPanel(true);
  }

  function handlePanelClose() {
    setShowPanel(false);
  }

  return (
    <div className={classes}>
      <img src={BackgroundImage} className="protection-discovery-details__bg-img"/>
      <div className="protection-discovery-details__content">
        <Typography as={TypographyType.Title} className="protection-discovery-details__title">
          Loop Earplugs
        </Typography>
        <Typography className="protection-discovery-details__text">
          Les Loop Quiet sont des bouchons d'oreille qui réduisent le bruit de 25 décibels. Fabriqué en silicone souple, Loop bloque la plupart des bruits pour vous donner la paix et rendre votre monde plus silencieux. Vivez la vie à votre volume, nous avons vos oreilles couvertes.
        </Typography>
        <AudioButton isStatic invertedColor onClick={handleDiscoverTest}/>
        <Typography className="protection-discovery-details__subtext">Tester la protection en conditions réelles</Typography>
        <Typography as={TypographyType.Title} className="protection-discovery-details__price">40€</Typography>
        <DiscoveryPanel
          className="protection-discovery-details__audio-panel"
          open={!!showPanel}
          onClose={handlePanelClose}
          type={""}
          volume={locationState?.volume}
        />
      </div>
    </div>
  );
};

export default ProtectionDiscoveryDetails;
