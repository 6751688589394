import React from "react";
import cx    from "classnames";
import "./Progress.scss";

type ProgressProps = {
  className?: string,
} & React.DetailedHTMLProps<React.ProgressHTMLAttributes<HTMLProgressElement>, HTMLProgressElement>;

/**
 * Progress Functional Component
 * @param {string} className - used to set a class on a higher element tag
 * @param max
 * @param props
 * @return {React.FC<ProgressProps>}
 */
const Progress: React.FC<ProgressProps> = ({className, max = 100, ...props}) => {

  const classes: string = cx(
    "progress",
    className,
  );

  return (
    <progress {...props} className={classes} max={max}/>
  );
};

export default Progress;
