import { ActionReducer } from "constants/interfaces/actionReducer"

export enum Actions {
    SET_PATIENT = "SET_PATIENT",
    EDIT_PATIENT = "EDIT_PATIENT",
    RESET_PATIENT = "RESET_PATIENT"
}

export type State = {
    id: number | null,
    firstname: string | null,
    lastname: string | null,
    email: string | null,
    address: string | null,
    city: string | null,
    postalCode: string | null,
}

export type Dispatch = (action: ActionReducer) => void
export type Reducer = (state: State, action: ActionReducer) => State
export type Context = { patientState: State, patientDispatch: Dispatch }
