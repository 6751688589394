export enum ProductType {
  Headset    = "headset",
  Protection = "protection",
  Device     = "device",
}

export enum Product {
  RIC     = "ric",
  BTE     = "bte",
  CIC     = "cic",
  LYR     = "lyr",
  HEA     = "hea",
  Headset = "headset",
  Plug    = "plug",
  Loop    = "loop",
}

export const ProductTestRoutePath = {
  Choices            : "/product-test/choices",
  SoundCalibration   : "/product-test/sound-calibration/:product_type",
  HeadsetChoices     : `/product-test/${ProductType.Headset}/choices`,
  ProtectionChoices  : `/product-test/${ProductType.Protection}/choices`,
  DeviceChoices      : `/product-test/${ProductType.Device}/choices`,
  ProductDiscovery   : "/product-test/discovery/:product",
  ProtectionDiscovery: `/product-test/protection/discovery`,
  ProtectionDiscoveryDetails: `/product-test/protection/discovery/:product(quiet|experience|pro)/details`,
  HeadsetVideo       : `/product-test/headset/discovery/:id(1|2|3)`
};
