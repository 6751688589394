import React, { useEffect, useRef, useState } from "react";
import { RouteComponentProps } from "react-router";
import cx from "classnames";
import Typography from "components/commons/Typography/Typography";
import { Direction } from "constants/enums/direction";
import { MeasurementType } from "constants/enums/routePath";
import { TonalAudiometryHzFrequency } from "constants/enums/tonalAudiometryHzFrequency";
import { setScores, setTonalAudiometryResult } from "contexts/hearingTest/actions";
import { useHearingTest } from "contexts/hearingTest/HearingTestContext";
import Player from "features/hearing-test/containers/Player/Player";
import ViewWrapper from "features/hearing-test/containers/ViewWrapper/ViewWrapper";
import "./HearingTestMeasurement.scss";
import { useParams } from "react-router-dom";
import { chunkBase, minDb } from "features/hearing-test/containers/Player/Player.dependancies";

interface IMeasurement {
  className?: string,
}

interface MeasurementUnmount {
  [key: string]: { [key: number]: number }
}

export
  /**
   * Measurement Functional Component
   * @param {string} className - used to set a class on a higher element tag
   * @param match
   * @constructor
   * @return {React.FC<IMeasurement>}
   */
  const HearingTestMeasurement: React.FC<IMeasurement & RouteComponentProps> = ({ className }) => {
    const [htState, htDispatch] = useHearingTest();
    const { type } = useParams<any>();
    const [title, setTitle] = useState("Appuyez sur le bouton de lecture pour lancer la tonalité");
    const [frequency, setFrequency] = useState<TonalAudiometryHzFrequency>();
    const [soundPosition, setSoundPosition] = useState<Direction>();
    const [dbPreset, setDbPreset] = useState(minDb);

    const unMountRef = useRef<MeasurementUnmount>({
      left: {},
      right: {}
    })

    useEffect(() => {
      switch (type) {
        case MeasurementType.RightLow: {
          const historyPreset = htState.tonalAudiometryResults.rightEar[TonalAudiometryHzFrequency.X4]
          unMountRef.current.right[TonalAudiometryHzFrequency.X4] = historyPreset ?? minDb

          setDbPreset(historyPreset ?? minDb)
          setFrequency(TonalAudiometryHzFrequency.X4);
          setSoundPosition(Direction.Right);
        }
          break;

        case MeasurementType.Right2k: {
          const historyPreset = htState.tonalAudiometryResults.rightEar[TonalAudiometryHzFrequency.X5]

          if (historyPreset !== null) {
            unMountRef.current.right[TonalAudiometryHzFrequency.X5] = historyPreset
            setDbPreset(historyPreset)
          }

          setFrequency(TonalAudiometryHzFrequency.X5);
          setSoundPosition(Direction.Right);
        }
          break;

        case MeasurementType.Right4k: {
          const historyPreset = htState.tonalAudiometryResults.rightEar[TonalAudiometryHzFrequency.X7]

          if (historyPreset !== null) {
            unMountRef.current.right[TonalAudiometryHzFrequency.X7] = historyPreset
            setDbPreset(historyPreset)
          }

          setFrequency(TonalAudiometryHzFrequency.X7);
          setSoundPosition(Direction.Right);
        }
          break;

        case MeasurementType.LeftLow: {
          const historyPreset = htState.tonalAudiometryResults.leftEar[TonalAudiometryHzFrequency.X4]
          unMountRef.current.left[TonalAudiometryHzFrequency.X4] = historyPreset ?? minDb

          setDbPreset(historyPreset ?? minDb)
          setFrequency(TonalAudiometryHzFrequency.X4);
          setSoundPosition(Direction.Left);
        }
          break;

        case MeasurementType.Left2k: {
          const historyPreset = htState.tonalAudiometryResults.leftEar[TonalAudiometryHzFrequency.X5]

          if (historyPreset !== null) {
            unMountRef.current.left[TonalAudiometryHzFrequency.X5] = historyPreset
            setDbPreset(historyPreset)
          }

          setFrequency(TonalAudiometryHzFrequency.X5);
          setSoundPosition(Direction.Left);

        }
          break;
        case MeasurementType.Left4k: {
          const historyPreset = htState.tonalAudiometryResults.leftEar[TonalAudiometryHzFrequency.X7]

          if (historyPreset !== null) {
            unMountRef.current.left[TonalAudiometryHzFrequency.X7] = historyPreset
            setDbPreset(historyPreset)
          }

          setFrequency(TonalAudiometryHzFrequency.X7);
          setSoundPosition(Direction.Left);
        }
          break;

      }
    }, [type]);


    const [text, tonalAudiometryResultState] = [
      `Oreille ${soundPosition === Direction.Right ? "droite" : "gauche"} ${frequency}Hz`,
      frequency && soundPosition && htState.tonalAudiometryResults[soundPosition === Direction.Right ? "rightEar" : "leftEar"]?.[frequency] !== null
    ];


    const classes: string = cx(
      "hearing-test-measurement",
      className,
    );


    /**
     * Handle player value changes
     * @param dB
     */
    function handleChange(dB: number) {
      htDispatch(setTonalAudiometryResult, soundPosition, frequency, dB);
      unMountRef.current[soundPosition as string][frequency as number] = dB
      setDbPreset(dB)
    }

    function noteFromTestPoints(points: number) {
      switch (true) {
        case points >= 80:
          return "Bonne acuité auditive"
        case points < 80 && points >= 40:
          return "Acuité auditive moyenne"
        case points < 40:
          return "Mauvaise acuité auditive"
        default:
          return "none"
      }
    }

    function pointsFromTest() {
      const results: { [key: string]: { value: number, note: string } } = {}

      for (const ear in unMountRef.current) {
        const sum = Math.abs(((minDb - unMountRef.current[ear][1000]) / chunkBase)) * 3
          + Math.abs(((minDb - unMountRef.current[ear][2000]) / chunkBase)) * 3
          + Math.abs(((minDb - unMountRef.current[ear][4000]) / chunkBase)) * 3

        const points = 100 - sum

        results[ear] = {
          value: points,
          note: noteFromTestPoints(points)
        }
      }

      return results
    }

    useEffect(() => {
      unMountRef.current.left = htState.tonalAudiometryResults.leftEar
      unMountRef.current.right = htState.tonalAudiometryResults.rightEar

      return () => {
        const points = pointsFromTest()
        const value = (points.left.value + points.right.value) / 2
        const average = {
          value: value,
          note: noteFromTestPoints(value)
        }

        htDispatch(setScores, points.left, points.right, average)
      }
    }, [])

    function handlePlay(status: boolean) {
      const newTitle = status
        ? "Ajuster le volume jusqu'à ce que la tonalité soit tout juste perceptible"
        : "Appuyez sur le bouton de lecture pour lancer la tonalité";
      setTitle(newTitle);
    }

    return (
      <ViewWrapper
        title={title}
        className={classes}
        disableNext={!tonalAudiometryResultState}
      >
        <Typography className="hearing-test-measurement__title">
          {text}
        </Typography>
        <Player
          frequency={frequency as TonalAudiometryHzFrequency}
          soundPosition={soundPosition as Direction}
          dbPreset={dbPreset}
          onChange={handleChange}
          onPlay={handlePlay}
        />
      </ViewWrapper>
    );
  };

export default HearingTestMeasurement;
