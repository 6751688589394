import { ReactComponent as ChevronLeftIcon } from "assets/img/svg/chevron_left.svg";
import { ReactComponent as HomeIcon } from "assets/img/svg/home.svg";
import cx from "classnames";
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import "./Header.scss";

type HeaderProps = {
  className?: string;
  title?: string;
};

/**
 * Header Functional Component
 * @param {string} className - used to set a class on a higher element tag
 * @param location
 * @param title
 * @constructor
 * @return {React.FC<HeaderProps>}
 */
const Header: React.FC<HeaderProps> = ({ className, title }) => {
  const history = useHistory();
  const location = useLocation();
  const [disableBack] = useState(false); // TODO : check on iframe too

  const classes: string = cx("header", className);
  const redirectUrl = location?.pathname.includes("experience-tour")
    ? "https://test-auditif.sonoly.fr"
    : "https://experience-tour.sonoly.fr/";

  function handleGoBack(): void {
    if (disableBack) return;
    // @ts-ignore
    history.goBack();
  }

  function handleGoHome(): void {
    // TODO : externalize for common purposes
    window.location.href = redirectUrl;
  }

  return (
    <div className={classes}>
      <button
        className={"header__btn header__back-btn"}
        disabled={disableBack}
        onClick={handleGoBack}
      >
        <ChevronLeftIcon />
      </button>
      <h3 className={"header__title"}>{title}</h3>
      <button className={"header__btn header__home-btn"} onClick={handleGoHome}>
        <HomeIcon />
      </button>
    </div>
  );
};

export default Header;
