import React                          from "react";
import cx                             from "classnames";
import uniqid                         from "uniqid";
import Typography                     from "components/commons/Typography/Typography";
import {Typography as TypographyType} from "constants/enums/typography";
import {Color}                        from "constants/enums/color";
import "./Textarea.scss";

type TextareaProps = {
  className?: string,
  label?: string,
  errors?: string[],
  resize?: boolean,
} & React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>

type TextareaType = React.ForwardRefExoticComponent<React.PropsWithoutRef<TextareaProps>>

/**
 * Textarea Functional Component
 * Use forward ref for compatibility w/ React Hook Form
 * @param {string} className - used to set a class on a higher element tag
 * @param errors
 * @param label
 * @param resize
 * @param rest
 * @constructor
 * @return {React.FC<TextareaProps>}
 */
const Textarea: TextareaType = React.forwardRef(({className, errors, label, resize, ...rest}, ref) => {
  const id        = rest?.id || uniqid();
  const hasErrors = !!errors?.length;

  const classes: string = cx(
    "textarea-wrapper",
    className,
    {
      "textarea-wrapper--has-errors": hasErrors,
      "textarea-wrapper--active-resize": resize
    }
  );

  return (
    <div className={classes} data-input-name={rest?.name}>
      {label && <label htmlFor={id} className={"textarea-wrapper__label"}>{label}</label>}
      {/*@ts-ignore*/}
      <textarea {...rest} ref={ref} id={id} className={"textarea-wrapper__textarea"} aria-invalid={hasErrors}/>
      {
        errors && (
          <Typography className={"textarea-wrapper__errors"} as={TypographyType.Small} color={Color.Error} role={"alert"}>
            {errors?.map(error => <span key={id + error}>{error}<br/></span>)}
          </Typography>
        )
      }
    </div>
  );
});

export default React.memo(Textarea);

