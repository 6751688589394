import React, { useEffect, useRef } from "react";
import cx from "classnames";
import AudioButton from "components/commons/AudioButton/AudioButton";
import Typography from "components/commons/Typography/Typography";
import { AnonymousFunction } from "constants/types/anonymousFunction";
import { ReactComponent as CheckIcon } from "assets/img/svg/check.svg";
import { ReactComponent as CrossIcon } from "assets/img/svg/cross.svg";
import { ReactComponent as CloseIcon } from "assets/img/svg/close.svg";
import "./DiscoveryPanel.scss";
import { each } from "lodash";


type DiscoveryPanelProps = {
  className?: string,
  open?: boolean,
  onClose?: AnonymousFunction,
  type: string,
  volume?: number,
  pack?: string
}

/**
 * DiscoveryPanel Functional Component
 * @param {string} className - used to set a class on a higher element tag
 * @param open
 * @param onClose
 * @param type
 * @param volume
 * @return {React.FC<DiscoveryPanelProps>}
 */
const DiscoveryPanel: React.FC<DiscoveryPanelProps> = ({ className, open = false, onClose, type, pack, volume }) => {
  const audioButtonsRef = useRef<any[]>([]);
  const currentIdPlayingRef = useRef<null | number>(null);
  const audioFilePrePath = `/assets/audio/product-test/discovery`;

  const classes: string = cx(
    "discovery-panel",
    { "discovery-panel--opened": open },
    className,
  );

  useEffect(() => {
    if (!open) {
      each(audioButtonsRef.current, audioButton => audioButton.stop());
    }
  }, [open]);

  function handlePlay(id: number) {
    if (typeof currentIdPlayingRef.current === "number") {
      audioButtonsRef.current[currentIdPlayingRef.current].stop();
    }
    currentIdPlayingRef.current = id
  }

  return (
    <div className={classes}>
      <button className="discovery-panel__close-btn" onClick={onClose}>
        <CloseIcon />
      </button>
      <div>
        <Typography className="discovery-panel__title">
          <CheckIcon />
          Simuler <strong>avec la technologie {pack}</strong>
        </Typography>
        <div className="discovery-panel__btn-container">
          <AudioButton ref={(el: any) => audioButtonsRef.current[0] = el} url={`${audioFilePrePath}/${type}-range/station.wav`} onClick={() => handlePlay(0)} data-text="À la gare" volume={volume} />
          <AudioButton ref={(el: any) => audioButtonsRef.current[1] = el} url={`${audioFilePrePath}/${type}-range/coffee.wav`} onClick={() => handlePlay(1)} data-text="Au café" volume={volume} />
          <AudioButton ref={(el: any) => audioButtonsRef.current[2] = el} url={`${audioFilePrePath}/${type}-range/tv.wav`} onClick={() => handlePlay(2)} data-text="Télévision" volume={volume} />
        </div>
      </div>
      <div>
        <Typography className="discovery-panel__title">
          <CrossIcon />
          Simuler <strong>sans la technologie {pack}</strong>
        </Typography>
        <div className="discovery-panel__btn-container">
          <AudioButton ref={(el: any) => audioButtonsRef.current[3] = el} url={`${audioFilePrePath}/no-edit/station.wav`} onClick={() => handlePlay(3)} data-text="À la gare" volume={volume} />
          <AudioButton ref={(el: any) => audioButtonsRef.current[4] = el} url={`${audioFilePrePath}/no-edit/coffee.wav`} onClick={() => handlePlay(4)} data-text="Au café" volume={volume} />
          <AudioButton ref={(el: any) => audioButtonsRef.current[5] = el} url={`${audioFilePrePath}/no-edit/tv.wav`} onClick={() => handlePlay(5)} data-text="Télévision" volume={volume} />
        </div>
      </div>
    </div>
  );
};

export default DiscoveryPanel;
