// TODO
const Page404 = (): JSX.Element => {
  return (
    <div>
      Not found (404)
    </div>
  );
};

export default Page404;
