import { ActionReducer } from "constants/interfaces/actionReducer"
import { Actions, State } from "./types"

const initialState = {
    id: null,
    firstname: null,
    lastname: null,
    email: null,
    address: null,
    city: null,
    postalCode: null,
}

function init(initialState: State): State {
    return initialState
}

function reducer(state: State, action: ActionReducer): State {
    switch (action.type) {
        case Actions.SET_PATIENT:
            return action.payload
        case Actions.EDIT_PATIENT:
            return {
                ...state,
                [action.payload.key]: action.payload.value
            }
        case Actions.RESET_PATIENT:
            return initialState
        default:
            return state
    }
}

export {
    initialState,
    init,
    reducer
}