import React, {DetailedHTMLProps, InputHTMLAttributes} from "react";
import cx                                              from "classnames";
import "./Checkbox.scss";

export type CheckboxProps = {
  as?: "radio"|"checkbox",
  className?: string,
  text?: string,
} & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

/**
 * Checkbox Functional Component
 * @param {"radio"|"checkbox"} as - used to simulate a radio checkbox (thought to add a common name for each checkbox)
 * @param {string} className - used to set a class on a higher element tag
 * @param {string} text
 * @param rest
 * @return {React.FC<CheckboxProps>}
 */
const Checkbox: React.FC<CheckboxProps> = ({as = "checkbox", className, text, ...rest}) => {
  const classes: string = cx(
    "checkbox",
    className,
  );

  return (
    <label className={classes}>
      <input {...rest} type={as} className="checkbox__input"/>
      <span className={cx("checkbox__checkmark")}/>
      {text}
    </label>
  );
};

export default Checkbox;
