import React                      from "react";
import SetupInstructions          from "../views/SetupInstructions/SetupInstructions";
import ExperienceTourChoices      from "../views/Choices/ExperienceTourChoices";
import ExperienceTourSimulator    from "../views/HearingLossSimulator/ExperienceTourSimulator";
import ExperienceTourQuestions    from "../views/Questions/ExperienceTourQuestions";
import ExperienceTourVideo        from "../views/Video/ExperienceTourVideo";
import {ExperienceTourRoutePath}  from "features/experience-tour/router/ExperienceTourRoutePath";
import ExperienceTourVideoChoices from "features/experience-tour/views/VideoChoices/ExperienceTourVideoChoices";
import ExperienceTourVirtualVideo from "features/experience-tour/views/VirtualVideo/ExperienceTourVirtualVideo";

export interface IExperienceTourRoute {
  path: string;
  component: React.FC;
  authed: boolean;
}

const ExperienceTourRoutes = [
  {authed: false, path: ExperienceTourRoutePath.Choices, component: ExperienceTourChoices},
  {authed: false, path: ExperienceTourRoutePath.SetupInstructions, component: SetupInstructions},
  {authed: false, path: ExperienceTourRoutePath.Video, component: ExperienceTourVideo},
  {authed: false, path: ExperienceTourRoutePath.Simulator, component: ExperienceTourSimulator},
  {authed: false, path: ExperienceTourRoutePath.Questions, component: ExperienceTourQuestions},
  {authed: false, path: ExperienceTourRoutePath.VirtualVideo, component: ExperienceTourVirtualVideo},
  {authed: false, path: ExperienceTourRoutePath.VideoChoices, component: ExperienceTourVideoChoices},
];

export default ExperienceTourRoutes;
