import { LocaleNamespace } from "config/intl/helpers";
import { ReactText } from "react";
import { useTranslation } from "react-i18next";
import { toast }                    from "react-toastify";
import NetworkException             from "services/api/exceptions/NetworkException";
import UnauthorizedException        from "services/api/exceptions/UnauthorizedException";
import UnprocessableEntityException from "services/api/exceptions/UnprocessableEntityException";
import { Exception }          from "services/api/types";

export function useCatchApiErrors(): (error: Exception, toastId?: ReactText) => void {
    const { t } = useTranslation(LocaleNamespace.Errors)

    return function (error: Exception, toastId?: ReactText) {
        if (toastId === undefined) toast.dismiss()

        let content = "";

        if (error instanceof UnauthorizedException) {
            console.error({error})
            content = t('api.server.unauthorized');
        } else if (error instanceof NetworkException) {
            console.error({error})
            content = t("api.server.network")

        } else if (error instanceof UnprocessableEntityException) {
            console.error({error})
            content = t("api.server.unprocessable_entity")
        } else {
            console.error({error})
            content = content = t("api.server.unknown")
        }

        toastId === undefined
            ? toast.error(content, {
                autoClose: 2000
            })
            : toast.update(toastId, {
                render: content,
                type: toast.TYPE.ERROR,
                autoClose: 2000,
                isLoading: false,
            })
    }
}
