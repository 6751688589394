import { useEffect, useState } from "react";

export function useAudio(url = "", volume = 1): [boolean, () => void] {
  const [audio] = useState(new Audio())
  const [playing, setPlaying] = useState(false)

  const toggle = () => setPlaying(!playing)
  const turnOff = () => setPlaying(false)

  if (!url) return [playing, toggle];

  useEffect(() => {
    fetch(url)
      .then(response => response.blob())
      .then(data => {
        audio.src = URL.createObjectURL(data)
      })

  }, [url])

  useEffect(() => {
    audio.volume = volume
  }, [volume])

  useEffect(() => {
    playing
      ? audio.play()
      : audio.pause()

    audio.addEventListener('ended', turnOff)

    return () => {
      audio.pause()
      audio.removeEventListener('ended', turnOff)
    }

  }, [playing])


  return [playing, toggle]
}
