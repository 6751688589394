export enum LocaleNamespace {
  Common = "common", // relatives to common text like button submit
  Pages  = "pages",
  Errors = "errors",
}

export const MonthLabels: {[key: number]: string} = {
  1: "Janvier",
  2: "Février",
  3: "Mars",
  4: "Avril",
  5: "Mai",
  6: "Juin",
  7: "Juillet",
  8:  "Août",
  9: "Septembre",
  10: "Octobre",
  11: "Novembre",
  12: "Décembre",
}