import {
  SET_AGE_RANGE,
  SET_GLOBAL_HEARING_SENSATION,
  SET_HIGH_PITCHED_SOUND_HEARING_SENSATION,
  SET_MUMBLING_HEARING_SENSATION,
  SET_NOISY_ENVIRONMENT_CONVERSATION_HEARING_SENSATION,
  SET_PHONE_HEARING_SENSATION,
  SET_TONAL_AUDIOMETRY_RESULT,
  RESET_HEARING_TEST,
  SET_SCORES
} from "../actionTypes";
import { HearingSensations } from "constants/enums/hearingSensations";
import { ActionReducer } from "constants/interfaces/actionReducer";
import { Direction } from "constants/enums/direction";
import { TonalAudiometryHzFrequency } from "constants/enums/tonalAudiometryHzFrequency";
import { FrequencyAdverb } from "constants/enums/frequencyAdverb";

export function setGlobalHearingSensation(sensation: HearingSensations): ActionReducer {
  return {
    type: SET_GLOBAL_HEARING_SENSATION,
    payload: sensation
  };
}

export function setTonalAudiometryResult(soundPosition: Direction, frequency: TonalAudiometryHzFrequency, decibels: number): ActionReducer {
  return {
    type: SET_TONAL_AUDIOMETRY_RESULT,
    payload: { soundPosition, frequency, decibels }
  };
}


export function setAgeRange(ageRange: string): ActionReducer {
  return {
    type: SET_AGE_RANGE,
    payload: ageRange,
  };
}


export function setMumblingHearingSensation(frequencyAdverb: FrequencyAdverb): ActionReducer {
  return {
    type: SET_MUMBLING_HEARING_SENSATION,
    payload: frequencyAdverb,
  };
}


export function setPhoneHearingSensation(frequencyAdverb: FrequencyAdverb): ActionReducer {
  return {
    type: SET_PHONE_HEARING_SENSATION,
    payload: frequencyAdverb,
  };
}


export function setHighPitchedSoundHearingSensation(frequencyAdverb: FrequencyAdverb): ActionReducer {
  return {
    type: SET_HIGH_PITCHED_SOUND_HEARING_SENSATION,
    payload: frequencyAdverb,
  };
}


export function setNoisyEnvironmentConversationHearingSensation(frequencyAdverb: FrequencyAdverb): ActionReducer {
  return {
    type: SET_NOISY_ENVIRONMENT_CONVERSATION_HEARING_SENSATION,
    payload: frequencyAdverb,
  };
}

export function resetHearingTest(): ActionReducer {
  return { type: RESET_HEARING_TEST }
}

export function setScores(leftEar: number, rightEar: number, average: number): ActionReducer {
  return {
    type: SET_SCORES,
    payload: { leftEar, rightEar, average }
  }
}