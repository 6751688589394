import React                          from "react";
import {useForm}                      from "react-hook-form";
import {toast}                        from "react-toastify";
import {useTranslation}               from "react-i18next";
import {RouteComponentProps}          from "react-router-dom";
import {RoutePath}                    from "constants/enums/routePath";
import {LocaleNamespace}              from "config/intl/helpers";
import {useRedirect}                  from "hooks/specific/useRedirect";
import {useCatchApiErrors}            from "hooks/specific/useCatchApiErrors";
import HookFormControl                from "components/containers/Forms/HookFormControl/HookFormControl";
import Button                         from "components/commons/Button/Button";
import {Size}                         from "constants/enums/size";
import API                            from "services/api";
import TokenService                   from "services/token";
import "./Login.scss";
import {ReactComponent as SonolyLogo} from "../../assets/img/svg/sonoly-black-n-blue-logo.svg";

const Login: React.FC<RouteComponentProps> = (): JSX.Element => {
  const {register, handleSubmit, formState: {errors}} = useForm({criteriaMode: "all"});
  const {t}                                           = useTranslation();
  const redirect                                      = useRedirect(RoutePath.Login);
  const apiErrorsHandler                              = useCatchApiErrors();

  const onSubmit = (values: { username: string, password: string }) => {

    const toastId = toast.loading(t(`${LocaleNamespace.Pages}:login.system.connection_progress`));

    API.postLoginCheck(values)
      .then(user => {
        toast.dismiss(toastId);
        TokenService.setUser({...user, email: values.username});
        redirect(RoutePath.SplashScreen);
      })
      .catch((error) => apiErrorsHandler(error, toastId));
  };

  return (
    <div className="login">
      <form className="login__form" onSubmit={handleSubmit(onSubmit)}>
        <SonolyLogo className="login__logo"/>
        {<HookFormControl
          className="login__form__item"
          data={{
            name        : "username",
            className   : "login__form__item__input-username",
            label       : "Nom d'utilisateur",
            autocomplete: "off",
            required    : {
              value  : true,
              message: "Username is required !"
            },
          }}
          handleRegister={register}
          errors={errors}
        />}

        {<HookFormControl
          className="login__form__item"
          data={{
            name        : "password",
            type        : "password",
            className   : "login__form__item__input-password",
            label       : "Mot de passe",
            autocomplete: "off",
            required    : {
              value  : true,
              message: "Password is required !"
            },
          }}
          handleRegister={register}
          errors={errors}
        />}

        <div className="login__form__item">
          <Button type={"submit"} size={Size.Large}>{t(`${LocaleNamespace.Common}:sign-in`)}</Button>
        </div>
      </form>
    </div>
  );
};

export default Login;
