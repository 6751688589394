import React, {useMemo}                       from "react";
import {useForm}                              from "react-hook-form";
import {TFunction, useTranslation}            from "react-i18next";
import cx                                     from "classnames";
import {LocaleNamespace}                      from "config/intl/helpers";
import Button                                 from "components/commons/Button/Button";
import Typography                             from "components/commons/Typography/Typography";
import {RoutePath}                            from "constants/enums/routePath";
import {Size}                                 from "constants/enums/size";
import {Typography as TypographyType}         from "constants/enums/typography";
import {FormDataValues as FormDataValuesType} from "constants/types/formDataValues";
import HookFormControl                        from "components/containers/Forms/HookFormControl/HookFormControl";
import {useRedirect}                          from "hooks/specific/useRedirect";
import API                                    from "services/api";
import { toast }                              from "react-toastify";
import { usePatientContext }                  from "contexts/global/patient";
import { setPatient }                         from "contexts/global/patient/actions";
import { useCatchApiErrors }                  from "hooks/specific/useCatchApiErrors";
import "./ScheduleSignIn.scss";


export function FormDataValue(t: TFunction<LocaleNamespace>): FormDataValuesType {
  return {
    name: "email",
    autocomplete: "off",
    placeholder: t(`schedule.sign-in.inputs.email.placeholder`),
    required: {
      value: true,
      message: t(`${LocaleNamespace.Errors}:form.input.required`),
    },
    pattern: {
      value: /^\S+@\S+$/i,
      message: t(`${LocaleNamespace.Errors}:form.input.email`),
    }
  };
}


type ScheduleSignInProps = {
  className?: string,
}

type FormValues = {
  email: string,
};


/**
 * ScheduleSignIn Functional Component
 * @param {string} className - used to set a class on a higher element tag
 * @constructor
 * @return {React.FC<ScheduleSignInProps>}
 */
const ScheduleSignIn: React.FC<ScheduleSignInProps> = ({ className }) => {
  const { register, handleSubmit, formState: { errors } } = useForm<FormValues>({
    criteriaMode: "all", // display all errors
  });
  const redirect = useRedirect(RoutePath.ScheduleSignIn);
  const { t } = useTranslation<LocaleNamespace>(LocaleNamespace.Pages);
  const memoizedFormData = useMemo(() => FormDataValue(t), []);
  const { patientDispatch } = usePatientContext();
  const apiErrorHandler = useCatchApiErrors();

  const classes: string = cx(
    "schedule-sign-in",
    className,
  );

  async function onSubmit(data: FormValues) {
    try {
      const patients = await API.getPatients({ omnisearch: data.email })

      if (patients.length === 0) {
        const content = t(
          `${LocaleNamespace.Errors}:api.patients.not_exists`,
          { email: data.email }
        );

        toast.error(content, {
          autoClose: 2000
        });

        return

      } else if (patients.length > 1) {
        console.error(`Schedule sign in: ${data.email} is duplicated.`)

        const content = t(
          `${LocaleNamespace.Errors}:api.patients.duplicated`,
          { email: data.email }
        );

        toast.error(content, {
          autoClose: 2000
        });

        return
      }

      patientDispatch(setPatient(patients[0]));
      redirect(RoutePath.ScheduleWelcome);
    } catch (error: any) {
      apiErrorHandler(error)
    }
  }

  return (
    <div className={classes}>
      <form className="schedule-sign-in__form" onSubmit={handleSubmit(onSubmit)}>
        <Typography as={TypographyType.Subtitle} className="schedule-sign-in__subtitle">{t("schedule.sign-in.title")}</Typography>
        <HookFormControl
          data={memoizedFormData}
          handleRegister={register}
          errors={errors}
        />
        <Button className="schedule-sign-in__submit-btn" type="submit"
          size={Size.Large}>{t(`${LocaleNamespace.Common}:sign-in`)}</Button>
      </form>
    </div>
  );
};

export default ScheduleSignIn;
