import { IViolation } from "../types";

class UnprocessableEntityException extends Error {
    name: string;
    message: string;
    violations: IViolation[];

    constructor(message: string, violations: IViolation[]) {
        super(message)
        this.name = "UnprocessableEntityException";
        this.message = message;
        this.violations = violations
    }
}

export default UnprocessableEntityException
