import {ExperienceTourRoutePath, ProductTestRoutePath, RoutePath} from "constants/enums/routePath";

interface DashboardMenuItem {
  untranslatedTitle: string,
  linkTo: string,
  key: string,
}

export const menuItems: DashboardMenuItem[] = [
  {
    untranslatedTitle: "Tester \n son audition",
    linkTo           : RoutePath.HearingTestSignChoices,
    key              : "hearing-test",
  }
];
