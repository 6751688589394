import { State as patientState } from "contexts/global/patient/types";
import { State as hearingState } from "contexts/hearingTest/interfaces";

const HEARING_TEST_EMAIL_HOOK =
  "https://hooks.zapier.com/hooks/catch/6936617/bmy78vs";

interface ISendHearingTestEmailPayload {
  hearingTest: hearingState;
  patient: patientState;
  user: { email: string | null };
}

type TSendHearingTestEmailFunc = (
  payload: ISendHearingTestEmailPayload
) => Promise<any>;

const sendHearingTestEmail: TSendHearingTestEmailFunc = (payload) => {
  if (process.env.NODE_ENV === "development") return Promise.resolve();

  return fetch(HEARING_TEST_EMAIL_HOOK, {
    method: "POST",
    body: JSON.stringify(payload),
  });
};

const ZAPIER = {
  sendHearingTestEmail,
};

export default ZAPIER;
