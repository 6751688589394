import React                     from "react";
import {RouteComponentProps}     from "react-router";
import cx                        from "classnames";
import Checkbox, {CheckboxProps} from "components/commons/Checkbox/Checkbox";
import {useHearingTest}          from "contexts/hearingTest/HearingTestContext";
import ViewWrapper               from "features/hearing-test/containers/ViewWrapper/ViewWrapper";
import {IQuestion, questions}    from "./Questions.dependencies";
import "./Questions.scss";
import Typography                from "../../../../components/commons/Typography/Typography";


const checkboxes: Array<CheckboxProps> = [
  {text: "Toujours", value: "always"},
  {text: "Souvent", value: "often"},
  {text: "Parfois", value: "sometimes"},
  {text: "Rarement", value: "occasionally"},
  {text: "Jamais", value: "never"},
];


interface IQuestions {
  className?: string,
}


/**
 * Questions Functional Component
 * @param {string} className - used to set a class on a higher element tag
 * @param match
 * @constructor
 * @return {React.FC<IQuestions>}
 */
const Questions: React.FC<IQuestions & RouteComponentProps> = ({className, match}) => {
  const [htState, htDispatch] = useHearingTest();

  // @ts-ignore
  const {part} = match.params;

  //Selected corrected value according to URL param
  const [title, filteredQuestions] = [
    `Questions - ${part === "1" ? "Première" : "Seconde"} partie`,
    part === "1" ? questions.slice(0, 2) : questions.slice(2),
  ];

  // Check if we need to disabled the next step
  const disableNext = ((state, questions) => (
    !questions.every(question => !!state?.hearingSensations?.[question.linkedStateProperty])
  ))(htState, filteredQuestions);

  const classes: string = cx(
    "questions",
    className,
  );

  function handleChange(e: any, action: IQuestion["action"]) {
    htDispatch(action, e.target.value);
  }


  return (
    <ViewWrapper
      className={classes}
      disableNext={disableNext}
      title={title}
    >
      {
        filteredQuestions.map(question => {
          return (
            <div className="questions__part" key={question.text}>
              <Typography className="questions__text">
                {question.text}
              </Typography>
              <div className="questions__checkboxes">
                {
                  checkboxes.map(checkboxe => (
                    <Checkbox
                      {...checkboxe}
                      key={checkboxe.value as string}
                      as={"radio"}
                      name={question.text}
                      defaultChecked={checkboxe.value === htState?.hearingSensations?.[question.linkedStateProperty]}
                      value={checkboxe.value}
                      onChange={e => handleChange(e, question.action)}
                    />
                  ))
                }
              </div>
            </div>
          );
        })
      }
    </ViewWrapper>
  );
};

export default Questions;
