import React, {useContext} from "react";
import {BrowserRouter}     from "react-router-dom";
import Routes              from "components/router/routes/Routes";
import {AuthContext}       from "contexts/global/AuthContext";


const Router: React.FC = () => {
  const {authed} = useContext(AuthContext);

  return (
    <BrowserRouter>
      <Routes authed={authed}/>
    </BrowserRouter>
  );
};

export default Router;
