import {FrequencyAdverb} from "constants/enums/frequencyAdverb";
import {ActionReducer}   from "constants/interfaces/actionReducer";
import {
  setHighPitchedSoundHearingSensation,
  setMumblingHearingSensation, setNoisyEnvironmentConversationHearingSensation,
  setPhoneHearingSensation
}                        from "contexts/hearingTest/actions";

export interface IQuestion {
  action: (frequencyAdverb: FrequencyAdverb) => ActionReducer
  linkedStateProperty: string,
  text: string,
}

export const questions: IQuestion[] = [
  {
    action             : setMumblingHearingSensation,
    linkedStateProperty: "mumbling",
    text               : "Trouvez-vous difficile de suivre une conversation en tête à tête, ou les gens semblent-ils marmonner ?",
  },
  {
    action             : setPhoneHearingSensation,
    linkedStateProperty: "phone",
    text               : "Trouvez-vous difficile d'avoir une conversation au téléphone ?",
  },
  {
    action             : setHighPitchedSoundHearingSensation,
    linkedStateProperty: "highPitchedSound",
    text               : "Trouvez-vous difficile d'entendre des sons aigus comme le chant des oiseaux ?",
  },
  {
    action             : setNoisyEnvironmentConversationHearingSensation,
    linkedStateProperty: "noisyEnvironmentConversation",
    text               : "Trouvez-vous difficile de suivre les conversations dans des environnements bruyants ?",
  },
];
