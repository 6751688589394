import React from "react";
import cx    from "classnames";
import "./Cols.scss";

type Cols = {
  className?: string,
}

/**
 * Cols Functional Component
 * Allows us to set automatic columns with the same size by using the CSS property/value as followed: display: grid;
 * @param {string} className - used to set a class on a higher element tag
 * @param children
 * @constructor
 * @return {React.FC<Cols>}
 */
const Cols: React.FC<Cols> = ({className, children, ...props}) => {
  const classes: string = cx(
    "cols",
    className,
  );

  return (
    <div {...props} className={classes} >
      {children}
    </div>
  );
};

export default Cols;
