import { IPostLoginCheckData } from "services/api/types"

const getUserToken = (): string | null => {
	const user = getUser()

	return (
			user !== null 
				? user.token 
				: null
	)
}

const getUserRefreshToken = (): string | null => {
	const user = getUser()

	return (
		user !== null
			? user.refresh_token
			: null
	)
}

const getUserEmail = ():  string | null => {
	const user = getUser()

	return (
		user !== null
			? user.email
			: null
	)
}

const getUserId = (): number | null => {
	const user = getUser()
	return (user !== null ? user.userId : null)
}

const getUser = (): IPostLoginCheckData & { email: string} | null => {
	const user = localStorage.getItem('user')

	try {
		if (user !== null) {
			return JSON.parse(user)
		}
	} catch (e) {
		console.error("User JSON data are malformed !")
	}

	return null
}

const setUser = (user: IPostLoginCheckData & { email: string}): void => {
	localStorage.setItem("user", JSON.stringify(user));
}

const removeUser = (): void => {
	localStorage.removeItem("user");
}


const TokenService = {
	getUserToken,
	getUserRefreshToken,
	getUserEmail,
	getUserId,
	getUser,
	setUser,
	removeUser
};

export default TokenService;

