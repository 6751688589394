import React from "react";
import cx from "classnames";
import { Trans, useTranslation } from "react-i18next";
import { LocaleNamespace } from "config/intl/helpers";
import { useHistory, useLocation } from "react-router-dom";
import HeadsetChoiceImg from "assets/img/backgrounds/product_test_choices_headset.jpg";
import ProtectionChoiceImg from "assets/img/backgrounds/product_test_choices_protection.jpg";
import DeviceChoiceImg from "assets/img/backgrounds/product_test_choices_device.jpg";
import Cols from "components/commons/Cols/Cols";
import ColumnSelection from "components/containers/Columns/ColumnSelection/ColumnSelection";
import { ProductType } from "features/product-test/router/ProductTestRoutePath";
import "./ProductTestChoices.scss"

type ProductTestChoicesProps = {
  className?: string,
}

/**
 * ProductTestChoices Functional Component
 * @param {string} className - used to set a class on a higher element tag
 * @return {React.FC<ProductTestChoicesProps>}
 */
const ProductTestChoices: React.FC<ProductTestChoicesProps> = ({ className }) => {
  const { t } = useTranslation(LocaleNamespace.Pages);
  const history = useHistory();
  const location = useLocation()

  const classes: string = cx(
    "product-test-choices",
    className,
  );

  function handleRedirect(productType: ProductType): void {
    switch (productType) {
      case ProductType.Device:
        history.push(`/product-test/${productType}/choices`)
        break;
      default:
        history.push(`/product-test/sound-calibration/${productType}`, { from: location?.pathname });
        break;

    }
  }

  return (
    <Cols className={classes}>
      <ColumnSelection
        button={{
          value: t("product-test.choices.headsetPart.btn"),
          onClick: () => handleRedirect(ProductType.Headset)
        }}
        className={"product-test-choices__column-selection product-test-choices__simulator-column-selection"}
        title={<Trans t={t} i18nKey="product-test.choices.headsetPart.title" />}
        subtitle={<Trans t={t} i18nKey="product-test.choices.headsetPart.subtitle" />}
        style={{ backgroundImg: HeadsetChoiceImg, invertedColor: true }}
      />
      <ColumnSelection
        button={{
          value: t("product-test.choices.protectionPart.btn"),
          onClick: () => handleRedirect(ProductType.Protection)
        }}
        className={"product-test-choices__column-selection"}
        title={<Trans t={t} i18nKey="product-test.choices.protectionPart.title" />}
        subtitle={<Trans t={t} i18nKey="product-test.choices.protectionPart.subtitle" />}
        style={{ backgroundImg: ProtectionChoiceImg, invertedColor: true }}
      />
      <ColumnSelection
        button={{
          value: t("product-test.choices.devicePart.btn"),
          onClick: () => handleRedirect(ProductType.Device)
        }}
        className={"product-test-choices__column-selection"}
        title={<Trans t={t} i18nKey="product-test.choices.devicePart.title" />}
        subtitle={<Trans t={t} i18nKey="product-test.choices.devicePart.subtitle" />}
        style={{ backgroundImg: DeviceChoiceImg, invertedColor: true }}
      />
    </Cols>
  );
};

export default ProductTestChoices;
