import Router from "components/router/Router";
import LaboratoryAttendanceListProvider from "contexts/global/LaboratoryAttendanceListContext";
import { PatientProvider } from "contexts/global/patient";
import PatientListProvider from "contexts/global/PatientListContext";
import { HearingTestProvider } from "contexts/hearingTest/HearingTestContext";
import { ToastContainer } from "react-toastify";
import "./App.css";


function App(): JSX.Element {
  return (
    <div className="app">
      <PatientProvider>
        <HearingTestProvider>
          <LaboratoryAttendanceListProvider>
            <PatientListProvider>
              <Router />
            </PatientListProvider>
          </LaboratoryAttendanceListProvider>
        </HearingTestProvider>
      </PatientProvider>
      <ToastContainer />
    </div>
  );
}


export default App;
