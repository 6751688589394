import React from "react";
import { RouteComponentProps } from "react-router";
import { Redirect } from "react-router-dom";
import { IgnoredRoutePath, RoutePath } from "constants/enums/routePath";


interface IUnauthenticatedRoute {
  component: React.ComponentType<RouteComponentProps & any>
  routeProps: RouteComponentProps<any>,
}


/**
 * UnauthenticatedRoute Functional Component
 * @param {string} className - used to set a class on a higher element tag
 * @constructor
 * @return {React.FC<IUnauthenticatedRoute>}
 */
const UnauthenticatedRoute: React.FC<IUnauthenticatedRoute> = ({ component: Component, routeProps }) => {

  const isIgnoredRoutePath = !(Object.values(IgnoredRoutePath) as Array<string>).includes(routeProps?.match?.path);

  return (
    <>
      {
        isIgnoredRoutePath
          ? <Redirect to={{ pathname: RoutePath.SplashScreen, state: { from: routeProps.location } }} />
          : <Component {...routeProps} authed={false} />
      }
    </>
  );
};

export default UnauthenticatedRoute;
