import React             from "react";
import {Trans, useTranslation}  from "react-i18next";
import cx                from "classnames";
import SelectionCard     from "components/containers/Cards/SelectionCard/SelectionCard";
import {LocaleNamespace} from "config/intl/helpers";
import {RoutePath}       from "constants/enums/routePath";
import {useRedirect}     from "hooks/specific/useRedirect";
import "./ScheduleSignChoices.scss";

type ScheduleSignChoicesProps = {
  className?: string,
}

/**
 * HearingTestSign Functional Component
 * @param {string} className - used to set a class on a higher element tag
 * @constructor
 * @return {React.FC<ScheduleSignChoicesProps>}
 */
const ScheduleSignChoices: React.FC<ScheduleSignChoicesProps> = ({className}) => {
  const {t}      = useTranslation(LocaleNamespace.Pages);
  const redirect = useRedirect(RoutePath.ScheduleSignChoices);

  const classes: string = cx(
    "schedule-sign-choices",
    className,
  );

  return (
    <div className={classes}>
      <SelectionCard
        button={{value: <Trans t={t} i18nKey="schedule.sign.signInPart.btn" />, onClick: () => redirect(RoutePath.ScheduleSignUp)}}
        title={"Vous n’êtes pas <br/> client SONOLY ?"}
        subtitle={"Inscrivez vous pour profiter pleinement de nos outils"}
      />
      <SelectionCard
        button={{value: <Trans t={t} i18nKey="schedule.sign.signInPart.btn" />, onClick: () => redirect(RoutePath.ScheduleSignIn)}}
        title={"Vous êtes déja <br/> client SONOLY ?"}
        subtitle={"Identifiez-vous pour prendre un rendez-vous "}
      />
    </div>
  );
};

export default ScheduleSignChoices;

