import { Patient } from "@audiowizard/common"
import React, { useState } from "react"

interface IPatientListState {
    patientList: Patient[]
    setPatientList: (patients: Patient[]) => void
  }

const defaultState = {
  patientList: [],
  setPatientList: () => { console.error('PatientList provider not found !') }
}

export const PatientListContext = React.createContext<IPatientListState>(defaultState)

const PatientListProvider: React.FC = ({ children }) => {
  const [patientList, setPatientList] = useState<Patient[]>([])

  return (
    <PatientListContext.Provider value={{ patientList, setPatientList }}>
      {children}
    </PatientListContext.Provider>
  )
}

export default PatientListProvider
