import React from "react";
import cx from "classnames";
import ViewWrapper from "features/hearing-test/containers/ViewWrapper/ViewWrapper";
import { setGlobalHearingSensation } from "contexts/hearingTest/actions";
import { useHearingTest } from "contexts/hearingTest/HearingTestContext";
import { HearingSensations } from "constants/enums/hearingSensations";
import "./Introduction.scss";
import Checkbox from "../../../../components/commons/Checkbox/Checkbox";


interface IIntroduction {
  className?: string,
}


const buttons = [
  {
    value: HearingSensations.Good,
    text: "Bonne",
    className: "introduction__good-btn",
  },
  {
    value: HearingSensations.Medium,
    text: "Moyenne",
    className: "introduction__medium-btn",
  },
  {
    value: HearingSensations.Bad,
    text: "Mauvaise",
    className: "introduction__bad-btn",
  },
];


/**
 * Introduction Functional Component
 * @param {string} className - used to set a class on a higher element tag
 * @constructor
 * @return {React.FC<IIntroduction>}
 */
const Introduction: React.FC<IIntroduction> = ({ className }) => {
  const [htState, htDispatch] = useHearingTest();
  const { global: globalHearingSensationState } = htState?.hearingSensations;

  const classes: string = cx(
    "introduction",
    className,
  );


  function handleChange(event: any) {
    htDispatch(setGlobalHearingSensation, event?.target?.value);
  }

  return (
    <ViewWrapper
      title="Comment décririez vous votre audition ?"
      className={classes}
      disableNext={!globalHearingSensationState}
    >
      {
        buttons.map(button => (
          <Checkbox
            {...button}
            key={button.value}
            as={"radio"}
            name={"describe_audition"}
            defaultChecked={button.value === htState?.hearingSensations?.global}
            value={button.value}
            onChange={handleChange}
          />
        ))
      }
    </ViewWrapper>
  );
};

export default Introduction;
