import React, { useEffect }           from "react";
import cx                             from "classnames";
import {ReactComponent as CheckIcon}  from "assets/img/svg/check_star.svg";
import {ReactComponent as DottedLine} from "assets/img/svg/hearing-test-thanks-dotted-line.svg";
import Button                         from "components/commons/Button/Button";
import Typography                     from "components/commons/Typography/Typography";
import {Typography as TypographyType} from "constants/enums/typography";
import {useRedirect}                  from "hooks/specific/useRedirect";
import {RoutePath}                    from "constants/enums/routePath";
import { usePatientContext }          from "contexts/global/patient";
import { resetPatient }               from "contexts/global/patient/actions";
import "./ScheduleThanks.scss";

type ScheduleThanksProps = {
  className?: string,
}

/**
 * Thanks Functional Component
 * @param {string} className - used to set a class on a higher element tag
 * @constructor
 * @return {React.FC<ScheduleThanksProps>}
 */
const ScheduleThanks: React.FC<ScheduleThanksProps> = ({className}) => {
  const redirect = useRedirect(RoutePath.ScheduleThanks);
  const { patientDispatch } = usePatientContext()

  const classes: string = cx(
    "schedule-thanks",
    className,
  );

  function handleClick() {
    redirect(RoutePath.Dashboard);
  }

  useEffect(() => {
    patientDispatch(resetPatient())
  }, [])

  return (
    <div className={classes}>
      {/* TODO: fix for the correct device view */}
      <DottedLine style={{width: "84vw", position: "fixed", top: "55vh", left: "9vw", display: "none"}}/>
      <div className="schedule-thanks__content">
        <CheckIcon className="schedule-thanks__check-icon"/>
        <Typography as={TypographyType.Title}
                    className="schedule-thanks__title">Merci d'avoir pris <br/> rendez-vous</Typography>
        <Button onClick={handleClick}>Revenir à l'accueil</Button>        
      </div>
    </div>
  );
};

export default ScheduleThanks;
