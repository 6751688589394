import cx from "classnames";
import Button from "components/commons/Button/Button";
import Typography from "components/commons/Typography/Typography";
import HookFormControl from "components/containers/Forms/HookFormControl/HookFormControl";
import { LocaleNamespace } from "config/intl/helpers";
import { RoutePath } from "constants/enums/routePath";
import { Size } from "constants/enums/size";
import { Typography as TypographyType } from "constants/enums/typography";
import { usePatientContext } from "contexts/global/patient";
import { setPatient } from "contexts/global/patient/actions";
import { useCatchApiErrors } from "hooks/specific/useCatchApiErrors";
import { useRedirect } from "hooks/specific/useRedirect";
import React, { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import API from "services/api/index";
import { FormDataKey, FormDataValues } from "./HearingTestSignUp.dependencies";
import "./HearingTestSignUp.scss";

type HearingTestSignUpProps = {
  className?: string;
};

type FormValues = {
  lastname: string;
  firstname: string;
  email: string;
  address: string;
  city: string;
  postal_code: string;
  gender: string;
  birthdate: string;
};

const HearingTestSignUp: React.FC<HearingTestSignUpProps> = ({ className }) => {
  const classes: string = cx("hearing-test-sign-up", className);
  const { patientDispatch } = usePatientContext();
  const redirect = useRedirect(RoutePath.HearingTestSignUp);
  const apiErrorHandler = useCatchApiErrors();
  const { t, ready } = useTranslation<LocaleNamespace>(LocaleNamespace.Pages);
  const memoizedFormData = useMemo(() => FormDataValues(t), [t]);
  const form = useForm<FormValues>({ criteriaMode: "all" });
  const history = useHistory();

  const onSubmit = async (data: FormValues) => {
    const patients = await API.getPatients({ omnisearch: data.email });
    const patient = patients.find((patient) => patient.email === data.email);
    if (patient) {
      patientDispatch(setPatient(patient));
      history.push("/hearing-test/thanks", { patientExists: true });
      return;
    }

    try {
      const patient = await API.postPatients({
        firstName: data.firstname,
        lastName: data.lastname,
        gender: data.gender,
        adress: data.address,
        city: data.city,
        email: data.email,
        cpo: data.postal_code,
      });

      patientDispatch(setPatient(patient));

      redirect(RoutePath.HearingTestThanks);
    } catch (error: any) {
      apiErrorHandler(error);
    }
  };

  return (
    <div className={classes}>
      {ready && (
        <form
          className={"hearing-test-sign-up__form"}
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <Typography
            as={TypographyType.Subtitle}
            className="hearing-test-sign-up__subtitle"
          >
            {t("hearing-test.sign-up.title")}
          </Typography>
          <Typography
            as={TypographyType.Subtitle}
            className="hearing-test-sign-up__secondarySubtitle"
          >
            Les champs suivi d'un astérisque{" "}
            <p
              style={{
                color: "#0000ec",
                display: "inline-block",
                verticalAlign: "super",
              }}
            >
              *
            </p>{" "}
            sont obligatoires
          </Typography>
          {memoizedFormData.map((formDataValue) => (
            <HookFormControl
              key={formDataValue.name}
              data={formDataValue}
              handleRegister={form.register}
              errors={form.formState.errors}
            />
          ))}
          <div className={"hearing-test-sign-up__gender-container"}>
            <p className="last-char-asterisk">{"Genre"}</p>
            <HookFormControl
              data={{
                name: FormDataKey.Gender,
                id: FormDataKey.Gender + "-1",
                type: "radio",
                value: "HOMME",
                label: "Homme",
                defaultChecked: true,
              }}
              handleRegister={form.register}
              errors={form.formState.errors}
            />

            <HookFormControl
              data={{
                name: FormDataKey.Gender,
                id: FormDataKey.Gender + "-2",
                type: "radio",
                value: "FEMME",
                label: "Femme",
              }}
              handleRegister={form.register}
              errors={form.formState.errors}
            />
          </div>
          <Button type={"submit"} size={Size.Small}>
            {t(`${LocaleNamespace.Common}:confirm`)}
          </Button>
        </form>
      )}
    </div>
  );
};

export default HearingTestSignUp;
