import { TBuildPathFunc, TBuildQueryParamsFunc, TValidatePathFunc } from "../types";

const routes = {
    login_check: "/login_check",
    refresh_token: "/api/token/refresh",
    patients: "/patients",
    patient: "/patients/:id",
    laboratories: "/laboratories",
    laboratory: "/laboratories/:id",
    laboratory_attendances: "/laboratory_attendances",
    user: "/users/:id",
    schedules: "/schedules",
    laboratories_available_slots: "/laboratories/available_slots",
    hearing_tests: "/hearing_tests",
    hearing_test: "/hearing_tests/:id"
};

const validatePath: TValidatePathFunc = (url) => {
    if (Object.values(routes).includes(url) === false) {
        throw new Error(`${url} is not part of routes config.`)
    }
}

export const buildPath: TBuildPathFunc = (routePath, routeParams) => {
    validatePath(routePath)

    return routePath.replace(new RegExp(/:(\w+)/g), function (match, matchReadable) {
        if ((matchReadable in routeParams) === false) {
            throw new Error(`value for "${match}" not found in ${JSON.stringify(routeParams)}.`)
        }

        return routeParams[matchReadable]
    })
}

export const buildQueryParams: TBuildQueryParamsFunc = (routePath, queryParams) => {
    validatePath(routePath)

    return '?' + Object
        .entries(queryParams)
        .map(([key, value]) => `${key}=${value}`)
        .join('&')
}

export default routes