import React from "react";
import { RouteComponentProps } from "react-router";
import ViewWrapper from "../../containers/Wrappers/ViewWrapper/ViewWrapper";


interface IAuthenticatedRoute {
  component: React.ComponentType<RouteComponentProps & any>
  path: string,
  routeProps: RouteComponentProps<any>,
}


/**
 * AuthenticatedRoute Functional Component
 * @constructor
 * @return {React.FC<IAuthenticatedRoute>}
 */
const AuthenticatedRoute: React.FC<IAuthenticatedRoute> = ({ component: Component, routeProps }) => {
  return (
    <ViewWrapper>
      <Component {...routeProps} authed={true} />
    </ViewWrapper>
  );
};

export default AuthenticatedRoute;
