import { Patient } from "@audiowizard/common";
import { ActionReducer } from "constants/interfaces/actionReducer";
import { Actions } from "./types";

function editPatient(key: string, value: string): ActionReducer {
    return {
        type: Actions.EDIT_PATIENT,
        payload: {
            key,
            value,
        }
    };
}

function setPatient(patient: Patient): ActionReducer {
    return {
        type: Actions.SET_PATIENT,
        payload: {
            id: patient.id,
            firstname: patient.firstName,
            lastname: patient.lastName,
            email: patient.email,
            address: patient.adress,
            city: patient.city,
            postalCode: patient.cpo
        }
    };
}

function resetPatient(): ActionReducer {
    return { type: Actions.RESET_PATIENT }
}

export {
    setPatient,
    editPatient,
    resetPatient
}