import { RoutePath } from "constants/enums/routePath"
import { AuthContext } from "contexts/global/AuthContext"
import { usePatientContext } from "contexts/global/patient"
import { resetPatient } from "contexts/global/patient/actions"
import { resetHearingTest } from "contexts/hearingTest/actions"
import { useHearingTestDispatch } from "contexts/hearingTest/HearingTestContext"
import { useContext, useMemo } from "react"

const destroyOnMatch = [
  RoutePath.Dashboard,
  RoutePath.HearingTestSignChoices,
  RoutePath.HearingTestSignUp,
  RoutePath.HearingTestSignIn,
  RoutePath.ScheduleSignChoices,
  RoutePath.ScheduleSignIn,
  RoutePath.ScheduleSignUp,
]

export function usePatientSessionDestroy(): (pathname: string) => void {
  const { patientState, patientDispatch } = usePatientContext()
  const { authed } = useContext(AuthContext)
  const htDispatch = useHearingTestDispatch()

  const isPatientSignedIn = useMemo(() => {
    return Object
      .values(patientState)
      .every(value => value !== null)

  }, [patientState])


  return function (pathname: string) {
    if (isPatientSignedIn === false)
      return

    if (destroyOnMatch.includes(pathname) || authed === false) {
      patientDispatch(resetPatient())
      htDispatch(resetHearingTest)

    }
  }
}