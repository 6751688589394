import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ReactComponent as SonolySplashScreenLogo } from "assets/img/svg/sonoly_splashscreen_brand.svg";
import { RoutePath } from "constants/enums/routePath";
import { AuthContext } from "contexts/global/AuthContext";
import "./SplashScreen.scss";
import TokenService from "services/token";
import API from "services/api";
import routes, { buildPath } from "services/api/config/routes";
import { PatientListContext } from "contexts/global/PatientListContext";
import { LaboratoryAttendanceListContext } from "contexts/global/LaboratoryAttendanceListContext";
import { useCatchApiErrors } from "hooks/specific/useCatchApiErrors";
import UnauthorizedException from "services/api/exceptions/UnauthorizedException";

export const SplashScreen: React.FC = () => {
  const history                          = useHistory();
  const { setPatientList }               = useContext(PatientListContext)
  const { setLaboratoryAttendancesList } = useContext(LaboratoryAttendanceListContext)
  const { setAuthed }                    = useContext(AuthContext)
  const [isLoading, setIsLoading]        = useState(true)
  const apiErrorHandler                  = useCatchApiErrors()

  function patientList() {
    return API.getPatients()
      .then(data => setPatientList(data))
      .catch(error => apiErrorHandler(error))
  }

  function laboratoryAttendanceList() {
    const userIri = buildPath(routes.user, {
      id: TokenService.getUserId()
    })

    return API.getLaboratoryAttendance({ user: userIri })
      .then(data => setLaboratoryAttendancesList(data))
      .catch(error => apiErrorHandler(error))
  }

  function refreshToken() {
    const user = TokenService.getUser()

    if (user === null) {
      return Promise.reject("Cannot refresh token, user not found.")
    }

    return API.postRefreshToken({ refresh_token: user.refresh_token })
      .then(data => {
        TokenService.setUser({
          ...user,
          token: data.token,
          refresh_token: data.refresh_token
        })
      })
  }

  function autoRefreshToken() {
    const refreshInterval = setInterval(() => {
      refreshToken()
        .catch((error: UnauthorizedException) => {
          console.error(`Refresh token worker has stopped, reason: ${error.message}`)
          TokenService.removeUser()
          history.push(RoutePath.Login)
          clearInterval(refreshInterval)
        })
    }, 5 * 60 * 1000)
  }

  function fetchAppData() {
    return Promise.all([
      patientList(),
      laboratoryAttendanceList(),
    ])
  }

  useEffect(() => {
    const userAlive = TokenService.getUser() !== null

    if (userAlive === false) {
      setTimeout(() => {
        history.push(RoutePath.Login)
      }, 2000)
      
      return
    }

    const firstLaunch = async () => {
      await refreshToken()
      await fetchAppData()

      setAuthed(true)
      setIsLoading(false)
      autoRefreshToken()
    }

    firstLaunch()
      .catch((error: UnauthorizedException) => {
        console.error(`Refresh token has expired, error: ${error.message}`)
        TokenService.removeUser()
        history.push(RoutePath.Login)
      })

  }, [])

  useEffect(() => {
    if (isLoading === false) {
      history.push(RoutePath.Dashboard)
    }
  }, [isLoading])

  return (
    <div className="splashscreen">
      <SonolySplashScreenLogo className="splashscreen__logo" />
    </div>
  );
}
