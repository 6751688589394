import React                     from "react";
import {useHistory}              from "react-router";
import {useTranslation, Trans}   from "react-i18next";
import cx                        from "classnames";
import SimulatorChoiceImg        from "assets/img/backgrounds/experience-tour_choices-simulator.jpg";
import VideoChoiceImg            from "assets/img/backgrounds/experience-tour_choices-video.jpg";
import QuestionChoiceImg         from "assets/img/backgrounds/experience-tour_choices-questions.jpg";
import Cols                      from "components/commons/Cols/Cols";
import ColumnSelection           from "components/containers/Columns/ColumnSelection/ColumnSelection";
import {LocaleNamespace}         from "config/intl/helpers";
import {ExperienceTourRoutePath} from "features/experience-tour/router/ExperienceTourRoutePath";
import "./ExperienceTourChoices.scss";

type ExperienceTourChoicesProps = {
  className?: string,
}

/**
 * ExperienceTourChoices Functional Component
 * @param {string} className - used to set a class on a higher element tag
 * @return {React.FC<ExperienceTourChoicesProps>}
 */
const ExperienceTourChoices: React.FC<ExperienceTourChoicesProps> = ({className}) => {
  const {t}             = useTranslation(LocaleNamespace.Pages);
  const history         = useHistory();

  const classes: string = cx(
    "experience-tour-choices",
    className,
  );

  function handleRedirect(path: string): void {
    history.push(path, {from: location?.pathname});
  }

  return (
    <Cols className={classes}>
      <ColumnSelection
        button={{value: t("experience-tour.choices.videoPart.btn"), onClick: () => handleRedirect(ExperienceTourRoutePath.VideoChoices)}}
        className={"experience-tour-choices__column-selection experience-tour-choices__simulator-column-selection"}        
        title={<Trans t={t} i18nKey="experience-tour.choices.videoPart.title" />}
        subtitle={t("experience-tour.choices.videoPart.subtitle")}
        style={{backgroundImg: SimulatorChoiceImg, invertedColor: true}}
      />
      <ColumnSelection
        button={{ value: t("experience-tour.choices.simulatorPart.btn"), onClick: () => handleRedirect(ExperienceTourRoutePath.SetupInstructions) }}
        className={"experience-tour-choices__column-selection"}
        title={<Trans t={t} i18nKey="experience-tour.choices.simulatorPart.title" />}
        subtitle={t("experience-tour.choices.simulatorPart.subtitle")}
        style={{backgroundImg: VideoChoiceImg, invertedColor: true}}
      />
      <ColumnSelection
        button={{value: t("experience-tour.choices.questionPart.btn"), onClick: () => handleRedirect(ExperienceTourRoutePath.Questions)}}
        className={"experience-tour-choices__column-selection"}
        title={<Trans t={t} i18nKey="experience-tour.choices.questionPart.title" />}
        subtitle={t("experience-tour.choices.questionPart.subtitle")}
        style={{backgroundImg: QuestionChoiceImg, invertedColor: true}}
      />
    </Cols>
  );
};

export default ExperienceTourChoices;
