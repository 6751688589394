class UnknownException extends Error {
    name: string;
    message: string;
    origin: Error;

    constructor(message: string, origin: Error) {
        super(message)
        this.name = "UnknownException"
        this.message = message
        this.origin = origin
    }
}

export default UnknownException