import {LocaleNamespace}                      from "config/intl/helpers";
import {TFunction}                            from "react-i18next";
import {FormDataValues as FormDataValuesType} from "constants/types/formDataValues";

export enum FormDataKey {
  Date                    = "date",
  AdditionnalInformation  = "additionnalInformation",
  Hour                    = "hour",
  Type                    = "type",
}

export function FormDataValues(t: TFunction<LocaleNamespace>): (FormDataValuesType & { name: FormDataKey })[] {
  const errorMessages = {
    required : t(`${LocaleNamespace.Errors}:form.input.required`),
    maxLength: (n: number) => t(`${LocaleNamespace.Errors}:form.input.maxLength`, {number: n}),
  };

  return [
    {
      type: "text",
      name        : FormDataKey.Date,
      label       : t("schedule.calendar.inputs.date.label"),
      autoComplete: "off",
      required    : {
        value  : true,
        message: errorMessages.required,
      },
      onClick: (e: any) => e.preventDefault() // prevent the display of the calendar
    },
    {
      required: {
        value: true,
        message: errorMessages.required,
      }
    },
    {
      required: {
        value: true,
        message: errorMessages.required,
      }
    },
    {
      type        : "textarea",
      name        : FormDataKey.AdditionnalInformation,
      label       : t("schedule.calendar.inputs.additionalInformation.label"),
      placeholder : t("schedule.calendar.inputs.additionalInformation.placeholder"),
      autoComplete: "off",
      rows: 4,
      autoCorrect: "on"
    },
  ];
}
