import React, { useMemo, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { find } from "lodash";
import cx from "classnames";
import Cols from "components/commons/Cols/Cols";
import DiscoveryCol from "features/product-test/containers/Cols/DiscoveryCol/DiscoveryCol";
import { Product } from "features/product-test/router/ProductTestRoutePath";
import "./ProductTestDiscovery.scss";

const columns = [
  {
    price: 950,
    review: {
      quality: 2,
      idealFor: {
        tv: true,
        nature: true
      }
    },
    subtext: "Simulation d'écoute",
    title: "Découverte [100% santé]",
    type: "discovery",
    short: "découverte",
  },
  {
    price: 1450,
    review: {
      quality: 3,
      idealFor: {
        tv: true,
        nature: true,
        music: true,
        bluetooth: true,
      }
    },
    subtext: "Simulation d'écoute",
    title: "Confort",
    type: "comfort",
    short: "confort",
  },
  {
    isPrime: true,
    price: 1950,
    review: {
      quality: 5,
      idealFor: {
        tv: true,
        nature: true,
        music: true,
        bluetooth: true,
        conversation: true,
        mealTime: true,
      }
    },
    subtext: "Simulation d'écoute",
    title: "Excellence",
    type: "excellence",
    short: "excellence",
  },
]

const lyrColumn = [{
  price: 1550,
  review: {
    quality: 4
  },
  subtext: "Simulation d'écoute",
  title: "Lyric",
  type: "excellence",
  short: "lyric",
}]

const heaColumn = [{
  isPrime: true,
  price: 1950,
  review: {
    quality: 5,
    idealFor: {
      tv: true,
      nature: true,
      music: true,
      bluetooth: true,
      conversation: true,
      mealTime: true,
    }
  },
  subtext: "Simulation d'écoute",
  title: "hearables",
  type: "excellence",
  short: "hearables",
}]

type ProductTestDiscoveryProps = {
  className?: string,
}

/**
 * ProductTestDiscovery Functional Component
 * @param {string} className - used to set a class on a higher element tag
 * @return {React.FC<ProductTestDiscoveryProps>}
 */
const ProductTestDiscovery: React.FC<ProductTestDiscoveryProps> = ({ className }) => {
  const [openedPanel, setOpenedPanel] = useState<number | string | null>(null);
  const panelsRef = useRef<any[]>([]);
  const { state: locationState } = useLocation();
  const { product } = useParams<any>();

  const classes: string = cx(
    "product-test-discovery",
    className,
  );

  // FIXME: quick correction for multi panels opened in the same time
  function handlePanelsDisplay(open: boolean, id: number | string) {
    if (!open) {
      setOpenedPanel(null);
    }
    const oldPanel = find(panelsRef.current, { id: openedPanel });
    oldPanel?.close();
    setOpenedPanel(id);
  }

  const cols = useMemo(() => {
    // @ts-ignore
    return {
      [Product.RIC]: columns,
      [Product.BTE]: columns,
      [Product.CIC]: columns,
      [Product.LYR]: lyrColumn,
      [Product.HEA]: heaColumn,
    }[product];
  }, [product]);

  return (
    <div className={classes}>
      {/* eslint-disable-next-line @typescript-eslint/no-var-requires */}
      <img src={require(`assets/img/backgrounds/product_test_discovery_${product}.jpg`).default} alt="" className="product-test-discovery__bg" />
      <Cols data-product-type={product}>
        {/*// @ts-ignore*/}
        {cols.map((columnProps, i) => <DiscoveryCol ref={el => panelsRef.current[i] = el} onPanel={handlePanelsDisplay} key={i} {...columnProps} volume={locationState?.volume} id={i} />)}
      </Cols>
    </div>
  );
};

export default ProductTestDiscovery;
