import React, {useEffect, useRef}     from "react";
import cx                             from "classnames";
import AudioButton                    from "../../../../../components/commons/AudioButton/AudioButton";
import {Typography as TypographyType} from "../../../../../constants/enums/typography";
import Typography                     from "../../../../../components/commons/Typography/Typography";
import "./HearingLossSimulatorBox.scss";
import {AnonymousFunction}            from "../../../../../constants/types/anonymousFunction";


type HearingLossSimulatorBoxProps = {
  className?: string,
  id?: string|number,
  isPlaying?: boolean,
  onPlaying?: AnonymousFunction,
  title: string,
  subtitle: string,
  text: string,
  url: string,
}

/**
 * HearingLossSimulatorBox Functional Component
 * @param {string} className - used to set a class on a higher element tag
 * @param id
 * @param isPlaying
 * @param onPlaying
 * @param title
 * @param subtitle
 * @param text
 * @param url
 * @return {React.FC<HearingLossSimulatorBoxProps>}
 */
const HearingLossSimulatorBox: React.FC<HearingLossSimulatorBoxProps> = ({className, id, isPlaying, onPlaying, title, subtitle, text, url}) => {
  const audioButtonRef = useRef(null);
  const classes: string = cx(
    "hearing-loss-simulator-box",
    className,
  );

  useEffect(() => {
    if (typeof isPlaying !== "boolean") return;
    if (!isPlaying) { // @ts-ignore
      audioButtonRef.current.stop();
    }
  }, [isPlaying]);

  function handlePlay() {
    if (onPlaying) onPlaying(id);
  }

  return (
    <div className={classes}>
      <AudioButton onClick={handlePlay} ref={audioButtonRef} url={url}/>
      <Typography as={TypographyType.Subtitle} className="hearing-loss-simulator-box__title" dangerouslySetInnerHTML={{__html: title}}></Typography>
      {/* FIXME: Find a better way to implement it, for example in a span. Actually, I don't have time to think about it */}
      <div className="hearing-loss-simulator-box__rest-container">
        <Typography className="hearing-loss-simulator-box__subtitle">{subtitle}</Typography>
        <Typography className="hearing-loss-simulator-box__text">{text}</Typography>
      </div>
    </div>
  );
};

export default HearingLossSimulatorBox;
