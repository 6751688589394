import React, {FunctionComponent, HTMLAttributes} from "react";
import cx                             from "classnames";
import {Typography as TypographyType} from "constants/enums/typography";
import {Color}                        from "constants/enums/color";
import "./Typography.scss";

type TypographyProps = {
  align?: "left"|"right"|"center"|"justify"|"initial"|"inherit",
  as?: TypographyType,
  className?: string,
  dangerouslySetInnerHTML?: any,
  color?: Color,
} & HTMLAttributes<any>

/**
 * Typography Functional Component
 * @param align
 * @param {TypographyType} as
 * @param children
 * @param {Color} color
 * @param {string} className - used to set a class on a higher element tag
 * @param rest - HTML Attributes
 * @constructor
 * @return {}
 */
const Typography: FunctionComponent<TypographyProps> = ({align, as = TypographyType.Body, children, color = Color.Text, className, ...rest}) => {

  const calculatedProps: { type: string, className: string } = {
    [TypographyType.Heading]   : {type: "h1", className: "typography--as-heading"},
    [TypographyType.Subheading]: {type: "h2", className: "typography--as-subheading"},
    [TypographyType.Title]     : {type: "h3", className: "typography--as-title"},
    [TypographyType.Subtitle]  : {type: "h4", className: "typography--as-subtitle"},
    [TypographyType.Body]      : {type: "p", className: "typography--as-body"},
    [TypographyType.Caption]   : {type: "p", className: "typography--as-caption"},
    [TypographyType.Small]     : {type: "p", className: "typography--as-small"},
  }[as];

  const classes: string = cx(
    "typography",
    calculatedProps.className,
    `typography--${color}-color`,
    className,
  );

  return React.createElement(calculatedProps.type, {className: classes, ["data-align"]: align,...rest}, children);
};

export default Typography;
