import React                          from "react";
import cx                             from "classnames";
import Typography                     from "components/commons/Typography/Typography";
import Button, {ButtonProps}          from "components/commons/Button/Button";
import Card                           from "components/commons/Card/Card";
import {Typography as TypographyType} from "constants/enums/typography";
import {AnonymousFunction}            from "constants/types/anonymousFunction";
import "./SelectionCard.scss";

type SelectionCardProps = {
  button?: {
    value: string | React.ReactElement,
    props?: ButtonProps,
    onClick?: AnonymousFunction,
  },
  className?: string,
  image?: any, // todo component
  subtitle?: string,
  style?: {
    invertedColor?: boolean,
  },
  title: string,
}

/**
 * SelectionCard Functional Component
 * @param {string} className - used to set a class on a higher element tag
 * @param image
 * @param title
 * @param subtitle
 * @param button
 * @param style
 * @return {React.FC<SelectionCardProps>}
 */
const SelectionCard: React.FC<SelectionCardProps> = ({className, image, title, subtitle, button, style}) => {

  const classes: string = cx(
    "selection-card",
    {
      "selection-card--inverted-color": style?.invertedColor,
      [`${className}--inverted-color`]: style?.invertedColor,
    },
    className,
  );

  return (
    <Card className={classes}>
      {image && <img src={image} alt=""/>}
      <Typography as={TypographyType.Title} className={"selection-card__title"} dangerouslySetInnerHTML={{__html: title}}></Typography>
      {
        subtitle &&
        <Typography as={TypographyType.Body} className={"selection-card__subtitle"}>{subtitle}</Typography>
      }
      {
        button &&
        <Button
          {...(button.props || {})}
          invertedColor={style?.invertedColor}
          onClick={button?.onClick}
        >
          {button.value}
        </Button>
      }
    </Card>
  );
};

export default SelectionCard;
