import React               from "react";
import cx                  from "classnames";
import SelectionCard       from "components/containers/Cards/SelectionCard/SelectionCard";
import {RoutePath}         from "constants/enums/routePath";
import {useRedirect}       from "hooks/specific/useRedirect";
import "./HearingTestSign.scss";

type HearingTestSignProps = {
  className?: string,
}

/**
 * HearingTestSign Functional Component
 * @param {string} className - used to set a class on a higher element tag
 * @constructor
 * @return {React.FC<HearingTestSignProps>}
 */
const HearingTestSign: React.FC<HearingTestSignProps> = ({className}) => {

  const redirect = useRedirect(RoutePath.HearingTestSignChoices);

  const classes: string = cx(
    "hearing-test-sign",
    className,
  );

  return (
    <div className={classes}>
      <SelectionCard
        button={{value: "Démarrer l’expérience", onClick: () => redirect(RoutePath.HearingTestSignUp)}}
        title={"Vous n’êtes pas <br/> client SONOLY ?"}
        subtitle={"Inscrivez vous pour profiter pleinement de nos outils"}
      />
      <SelectionCard
        button={{value: "Continuer l’expérience", onClick: () => redirect(RoutePath.HearingTestSignIn)}}
        title={"Vous êtes déja <br/> client SONOLY ?"}
        subtitle={"Identifiez-vous pour un accès rapide aux tests "}
      />
    </div>
  );
};

export default HearingTestSign;
