import React                              from "react";
import cx                                 from "classnames";
import {ReactComponent as QuietPlaceLogo} from "assets/img/svg/quiet_place.svg";
import Typography                         from "components/commons/Typography/Typography";
import ViewWrapper, {ViewWrapperProps}    from "features/hearing-test/containers/ViewWrapper/ViewWrapper";
import "./SetupInstructions.scss";

type SetupInstructionsProps = {
  className?: string,
}

const viewWrapperProps: ViewWrapperProps = {
  title: "Avant de commencer",
};

/**
 * SetupInstructions Functional Component
 * @param {string} className - used to set a class on a higher element tag
 * @constructor
 * @return {React.FC<SetupInstructionsProps>}
 */
const SetupInstructions: React.FC<SetupInstructionsProps> = ({className}) => {
  const classes: string = cx(
    "setup-instructions",
    className,
  );

  return (
    <ViewWrapper {...viewWrapperProps} className={classes}>      
      <div className="setup-instructions__instruction">
        <QuietPlaceLogo className="setup-instructions__logo" width={70} height={100}/>
        <Typography>Assurez-vous d'être dans le calme</Typography>
      </div>
    </ViewWrapper>
  );
};

export default SetupInstructions;
