class NetworkException extends Error {
    name: string;
    message: string;
    dns?: string;
    method?: string;
    requestUri?: string;

    constructor(
        message: string,
        dns: string | undefined,
        method: string | undefined,
        requestUri: string | undefined
    ) {
        super(message)
        this.name = "NetworkException"
        this.message = message
        this.dns = dns
        this.method = method
        this.requestUri = requestUri
    }
}

export default NetworkException