import { LaboratoryAttendance } from "@audiowizard/common"
import React, { useState } from "react"

interface ILaboratoryAttendanceState {
    laboratoryAttendancesList: LaboratoryAttendance[]
    setLaboratoryAttendancesList: (laboratoryAttendancesList: LaboratoryAttendance[]) => void
  }

const defaultState = {
  laboratoryAttendancesList: [],
  setLaboratoryAttendancesList: () => { console.error('laboratoryAttendancesList provider not found !') }
}

export const LaboratoryAttendanceListContext = React.createContext<ILaboratoryAttendanceState>(defaultState)

const LaboratoryAttendanceListProvider: React.FC = ({ children }) => {
  const [laboratoryAttendancesList, setLaboratoryAttendancesList] = useState<LaboratoryAttendance[]>([])

  return (
    <LaboratoryAttendanceListContext.Provider value={{ laboratoryAttendancesList, setLaboratoryAttendancesList }}>
      {children}
    </LaboratoryAttendanceListContext.Provider>
  )
}

export default LaboratoryAttendanceListProvider
