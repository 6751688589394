import React, {useState} from "react";
import cx                from "classnames";
import Cols                    from "components/commons/Cols/Cols";
import HearingLossSimulatorBox from "../../containers/Boxes/HearingLossSimulatorBox/HearingLossSimulatorBox";
import "./ExperienceTourSimulator.scss";

type ExperienceTourSimulatorProps = {
  className?: string,
}

/**
 * HearingLossSimulator Functional Component
 * @param {string} className - used to set a class on a higher element tag
 * @return {React.FC<ExperienceTourSimulatorProps>}
 */
const ExperienceTourSimulator: React.FC<ExperienceTourSimulatorProps> = ({className}) => {
  const [currentSound, setCurrentSound] = useState<string|number|null>(null);
  const classes: string = cx(
    "hearing-loss-simulator",
    className,
  );

  function handlePlay(id: string|number) {
    setCurrentSound(id);
  }

  // TODO: loop on it
  return (
    <Cols className={classes}>
      <HearingLossSimulatorBox
        id={1}
        isPlaying={currentSound === 1}
        onPlaying={handlePlay}
        title={"Audition <br/> normale"}
        subtitle={"0% à 10%"}
        text={"de perte auditive moyenne"}
        url={"/assets/audio/experience-tour/experience-tour-a-quality.wav"}
        className="hearing-loss-simulator__box"
      />
      <HearingLossSimulatorBox
        id={2}
        isPlaying={currentSound === 2}
        onPlaying={handlePlay}
        title={"Perte <br/> légère"}
        subtitle={"20% à 40%"}
        text={"de perte auditive moyenne"}
        url={"/assets/audio/experience-tour/experience-tour-b-quality.wav"}
        className="hearing-loss-simulator__box"
      />
      <HearingLossSimulatorBox
        id={3}
        isPlaying={currentSound === 3}
        onPlaying={handlePlay}
        title={"Perte <br/> moyenne"}
        subtitle={"40% à 70%"}
        text={"de perte auditive moyenne"}
        url={"/assets/audio/experience-tour/experience-tour-c-quality.wav"}
        className="hearing-loss-simulator__box"
      />
      <HearingLossSimulatorBox
        id={4}
        isPlaying={currentSound === 4}
        onPlaying={handlePlay}
        title={"Perte <br/> sévère / profonde"}
        subtitle={"plus de 70%"}
        text={"de perte auditive moyenne"}
        url={"/assets/audio/experience-tour/experience-tour-d-quality.wav"}
        className="hearing-loss-simulator__box"
      />
    </Cols>
  );
};

export default ExperienceTourSimulator;
