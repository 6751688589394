import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import cx from "classnames";
import Button from "components/commons/Button/Button";
import Cols from "components/commons/Cols/Cols";
import Typography from "components/commons/Typography/Typography";
import HookFormControl from "components/containers/Forms/HookFormControl/HookFormControl";
import { LocaleNamespace } from "config/intl/helpers";
import { RoutePath } from "constants/enums/routePath";
import { Size } from "constants/enums/size";
import { Typography as TypographyType } from "constants/enums/typography";
import { useRedirect } from "hooks/specific/useRedirect";
import { FormDataKey, FormDataValues } from "./ScheduleCalendar.dependencies";
import Calendar, { CalendarTileProperties } from 'react-calendar';
import { usePatientContext } from "contexts/global/patient";
import { Color } from "constants/enums/color";
import API from "services/api";
import { useCatchApiErrors } from "hooks/specific/useCatchApiErrors";
import routes, { buildPath } from "services/api/config/routes";
import TokenService from "services/token";
import { IGetLaboratoriesAvailableSlotsData, IPostSchedulesPayload } from "services/api/types";
import { LaboratoryAttendanceListContext } from "contexts/global/LaboratoryAttendanceListContext";
import "./ScheduleCalendar.scss";

type ScheduleCalendarProps = {
  className?: string,
}

interface ExtendedLaboratoriesAvailableSlotsData extends IGetLaboratoriesAvailableSlotsData {
  laboratory?: string
}

interface SlotOption {
  laboratory: string
  dateStart: string
  dateEnd: string
  label: string
}

type FormValues = {
  date: string,
  hour: string,
  additionnalInformation: string,
  type: string
}

/**
 * ScheduleCalendar Functional Component
 * @param {string} className - used to set a class on a higher element tag
 * @constructor
 * @return {React.FC<ScheduleCalendarProps>}
 */
const ScheduleCalendar: React.FC<ScheduleCalendarProps> = ({ className }) => {
  const { t } = useTranslation<LocaleNamespace>(LocaleNamespace.Pages);
  const redirect = useRedirect(RoutePath.ScheduleCalendar);
  const { patientState } = usePatientContext()
  const { laboratoryAttendancesList } = useContext(LaboratoryAttendanceListContext)
  const Form = useForm<FormValues>({ criteriaMode: "all" });
  const memoizedFormData = useMemo(() => FormDataValues(t), []);
  const [calendarValue, setCalendarValue] = useState(new Date());
  const [slots, setSlots] = useState<SlotOption[]>([])
  const [slotsDisabled, setSlotsDisabled] = useState(true)
  const watchScheduleType = Form.watch("type", "PREMIERE_VISITE");
  const apiErrorHandler = useCatchApiErrors()

  const classes: string = cx(
    "schedule-calendar",
    className,
  );


  function searchAllDayLaboratories(date: Date) {
    const weekday = date.toLocaleDateString('fr-FR', { weekday: "long" }).toUpperCase()
    const attendances = laboratoryAttendancesList.filter(attendance => attendance.day === weekday)
    const laboratories = attendances.map(attendance => attendance.laboratory)

    return laboratories.filter(
      (laboratory, index) =>
        laboratories.findIndex(
          (lab) => lab?.id === laboratory?.id
        ) === index
    )
  }

  function aggregateSlots(AllDaySlots: ExtendedLaboratoriesAvailableSlotsData[]) {
    const uniqueSlotsMap = new Map()

    for (const halfDaySlots of AllDaySlots) {
      for (const slot of Object.values(halfDaySlots.availableSlots)) {
        if (uniqueSlotsMap.has(slot.start) === false) {
          const timeStart = (new Date(slot.start))
            .toLocaleTimeString('fr-FR')
            .slice(0, -3)

          const timeEnd = (new Date(slot.end))
            .toLocaleTimeString('fr-FR')
            .slice(0, -3)

          uniqueSlotsMap.set(slot.start, {
            laboratory: halfDaySlots.laboratory,
            dateStart: slot.start,
            dateEnd: slot.end,
            label: `${timeStart} - ${timeEnd}`
          })
        }
      }
    }

    function sortEarlyFirst(a: SlotOption, b: SlotOption) {
      return a.dateStart < b.dateStart ? -1 : a.dateStart > b.dateStart ? 1 : 0
    }

    return Array
      .from(uniqueSlotsMap.values())
      .sort(sortEarlyFirst)
  }

  useEffect(() => {
    updateSlots(calendarValue)
  }, [watchScheduleType])

  function updateSlots(date: Date) {
    setCalendarValue(date)
    setSlotsDisabled(true)
    Form.setValue("date", date.toLocaleDateString('fr-FR'))

    const dateFormatted = [
      date.getFullYear(),
      date.getMonth() + 1,
      date.getDate()
    ].join('-')

    const laboratories = searchAllDayLaboratories(date)
    const promises: Promise<ExtendedLaboratoriesAvailableSlotsData>[] = []

    laboratories.forEach(lab => {
      const payload = {
        date: dateFormatted,
        laboratory: buildPath(routes.laboratory, { id: lab?.id }),
        scheduleStatus: watchScheduleType
      }

      promises.push(
        API.getLaboratoriesAvailableSlots(payload).then(data => ({
          laboratory: buildPath(routes.laboratory, { id: lab?.id }),
          availableSlots: data.availableSlots
        }))
      )
    })

    Promise.all(promises)
      .then(data => {
        setSlots(aggregateSlots(data))
        setSlotsDisabled(false)
      })
      .catch(error => apiErrorHandler(error))
  }

  function handleSubmit(data: FormValues) {
    const slot = slots.find(slot => slot.dateStart === data.hour)

    if (slot === undefined) {
      throw new Error("Corresponding slot cannot be found")
    }

    const payload: IPostSchedulesPayload = {
      dateOf: slot.dateStart,
      dateEnd: slot.dateEnd,
      state: "WAITING",
      status: data.type,
      laboratory: slot.laboratory,
      user: buildPath(
        routes.user, {
        id: TokenService.getUserId()
      }),
      patient: buildPath(
        routes.patient,
        { id: patientState.id }
      ),
    }

    if (data.additionnalInformation.length > 0) {
      payload.preScheduleNote = data.additionnalInformation
    }

    API.postSchedules(payload)
      .then(() => redirect(RoutePath.ScheduleThanks))
      .catch(error => apiErrorHandler(error))
  }

  const disableSundayTile = ({date}: CalendarTileProperties) => date.getDay() === 0
  
   return (
    <Cols className={classes}>
      <div className="schedule-calendar__calendar-container">
        <Calendar
          className="schedule-calendar__calendar"
          locale="fr-FR"
          minDate={new Date()}
          tileDisabled={disableSundayTile}
          onChange={updateSlots}
          value={calendarValue}
        />
      </div>
      <form className="schedule-calendar__form" onSubmit={Form.handleSubmit(handleSubmit)}>
        <Typography as={TypographyType.Subtitle}>
          {patientState.firstname}
        </Typography>

        <Typography className="schedule-calendar__title">
          {t("schedule.calendar.title")}
        </Typography>

        <HookFormControl
          key={memoizedFormData[0].name}
          data={memoizedFormData[0]}
          handleRegister={Form.register}
          errors={Form.formState.errors}
        />

        {/* TODO: Wrap in custom Select component or adapt HookFormControl to handle selects*/}
        <div className="input-wrapper">
          <label className="input-wrapper__label" >
            {t("schedule.calendar.inputs.type.label")}
          </label>

          <select
            {...Form.register(FormDataKey.Type, memoizedFormData[1])}
            className="input-wrapper__input"
          >
            <option value="PREMIERE_VISITE">
              {t("schedule.calendar.inputs.type.options.premiere_visite")}
            </option>
            <option value="LIVRAISON_SAV">
              {t("schedule.calendar.inputs.type.options.livraison_sav")}
            </option>
          </select>

          {
            Form.formState.errors.type && (
              <Typography
                className={"input-wrapper__errors"}
                as={TypographyType.Small}
                color={Color.Error}
                role={"alert"}
              >
                {Form.formState.errors.type.message}
              </Typography>
            )
          }
        </div>

        {/* TODO: Wrap in custom Select component or adapt HookFormControl to handle selects*/}
        <div className={Form.formState.errors.hour ? "input-wrapper input-wrapper--has-errors" : "input-wrapper"}>
          <label className="input-wrapper__label" >
            {t("schedule.calendar.inputs.hour.label")}
          </label>
          <select
            {...Form.register(FormDataKey.Hour, memoizedFormData[2])}
            className="input-wrapper__input"
            disabled={slotsDisabled}
            defaultValue=""
          >
            {slots.length === 0
              ? (<option value="" disabled hidden>Aucun créneaux...</option>)
              : (<option value="" disabled hidden>Choisir un créneau...</option>)}

            {slots.length > 0 && (slots.map((slot, key) => (
              <option key={key} value={slot.dateStart}>{slot.label}</option>
            )))}
          </select>
          {
            Form.formState.errors.hour && (
              <Typography
                className={"input-wrapper__errors"}
                as={TypographyType.Small}
                color={Color.Error}
                role={"alert"}
              >
                {Form.formState.errors.hour.message}
              </Typography>
            )
          }
        </div>

        <HookFormControl
          key={memoizedFormData[3].name}
          data={memoizedFormData[3]}
          handleRegister={Form.register}
          errors={Form.formState.errors}
        />
        <Button type={"submit"} size={Size.Med} disabled={slotsDisabled}>
          {t("schedule.calendar.btn")}
        </Button>
      </form>
    </Cols>
  );
};

export default ScheduleCalendar;
